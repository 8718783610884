
const API_URL = "https://apiv2.zemus.info"
const API_SCHEME = "https"
const API_HOST_NAME = "apiv2.zemus.info"
const API_PORT =  "443"

export default {  
    API_URL,
    API_SCHEME,
    API_HOST_NAME,
    API_PORT
}



