import React, { useState, useEffect } from "react";
import "./articles.css";
import Favo from "../../media/fav.jpeg"; 
import { removeFavorite, createReview, fetchLinkedReview } from "../../HTTP/articles" 
import * as helpers from "../../Helper/utils"; // Importez tout le module helper
import userApi from "../../HTTP/user"

const ArticlesFav = () => {
  const user = JSON.parse(localStorage.getItem('user'))
  const favorites = JSON.parse(localStorage.getItem("favorites"))
  const [showPopup, setShowPopup] = useState(false);
  const [error, setError] = useState("");
  const [selection, setSelection] = useState([]); 
  const [searchTerm, setSearchTerm] = useState("");
  const [reviews, setReviews] = useState([]); 
	  useEffect(() => {
      const fetchReviews = async () => {
        try{
          const reviewsData = await userApi.getUserReviews(); // Assurez-vous que ceci renvoie un tableau
          console.log(reviewsData.reviews); // Debug: Qu'affiche cela ?
          setReviews(reviewsData.reviews);
        }
        catch{
          console.log('no reviews')
        }
      };
	  
		  fetchReviews();
	  }, []);
 

  // Fonction pour mettre à jour la sélection
  const updateSelection = () => {
    let favs = [];
    const checkedCheckboxes = document.querySelectorAll('.itemfav input[type="checkbox"]:checked');
    checkedCheckboxes.forEach(checkbox => {
      let parentItem = checkbox.closest('.itemfav');
      if (parentItem) {
        let dataId = parentItem.getAttribute('data-id');
        if (dataId) {
          favs.push(favorites[dataId]);
        }
      }
    });
    setSelection(favs);
    console.log(selection)
  };
  const togglePopup = () => {
    updateSelection();
    setShowPopup(!showPopup);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredFavorites = favorites.filter(fav => 
    fav.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    fav.link.toLowerCase().includes(searchTerm.toLowerCase()) ||
    fav.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
    fav.country.toLowerCase().includes(searchTerm.toLowerCase())
  );
 
  function removeFav(i) {
    const favorite = {
      link: helpers.htmlDecode(favorites[i].link)
    }
    removeFavorite(favorite).then((res) => {
        console.log(res) 
        document.getElementById("fav_"+i).remove();
        favorites.splice(i, 1); 
        localStorage.setItem('favorites', JSON.stringify(favorites)); 
    })
    alert('Favori supprimé');
  } 
 
  const addReview= () => {
    const theme = document.getElementById('review_theme').value
    const presentation = document.getElementById('review_presentation').value
    createReview(theme, presentation, selection).then((res) => {
      console.log(res) 
      setShowPopup(!showPopup);
      alert('Revue de presse créée')
      window.location.href = `https://static.zemus.info/?id=${res.newReview}&redirect=favourites`; 
    }) 
  }

  const addToExistingReview = () => { 
      const jsonString = JSON.stringify(selection) 
      const encodedString = encodeURIComponent(jsonString) 
      window.location.href = `/addtoreview?data=${encodedString}`
  }

  const addToExistingFriendReview = () => { 
    const jsonString = JSON.stringify(selection) 
    const encodedString = encodeURIComponent(jsonString) 
    window.location.href = `/addtofriendreview?data=${encodedString}`
}

  return (
      <> 
            { showPopup && <div className="blur-background"></div> }
            { showPopup && (
              <>

                <div className="popup"> 
                  <div style={{ display: "flex" }} className="headtitle">
                    <h2>Créer une revue de Presse</h2>
                    <span onClick={togglePopup} className="material-symbols-outlined">
                      close
                    </span>
                  </div>
                  <div>
                    <div className="Message">
                      <input
                        title="Write Message"
                        name="theme"
                        placeholder="Thème..."
                        className="MsgInput"
                        type="text"
                        maxLength="255"
                        id="review_theme"
                      /> 
                    </div>
                    <div className="Message">
                      <textarea
                        name="presentation"
                        placeholder="Description..."
                        className="MsgInput"
                        rows={4}
                        maxLength="255"
                        id="review_presentation"
                      /> 
                    </div>
                    <div className="Message">
                       <button 
                        className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-5 rounded"
                        onClick={addReview}
                        >Valider</button>
                    </div>
                    <div className="Message">
                    {
                      selection.map((fav, i) => (
                        <img
                            key={i}
                            className="item-photofav-add"
                            src={ helpers.htmlDecode(fav.image || process.env.PUBLIC_URL + "/news_icon2.jpg") /**  IMAGE  */}
                            alt="article"  
                          />
                      ))
                    }
                    </div>
                  </div>
                </div>
              </>
            )}                 

        <section className="articlesfav">
          <div className="search-bar">
            <input
              type="text"
              placeholder="Rechercher parmi les favoris..."
              value={searchTerm}
              onChange={handleSearchChange}
              className="search-input"
            />
        </div>
            { 
              (() => {
                if (error.length > 0){
                  return <h1>{error}</h1>
                }
              })
              ()
            } 

            <div className="titleDivfav" style={{ display: "flex", marginTop: 55, marginBottom: 35}}>
                <button onClick={togglePopup} className="mx-1">Créer une revue de presse</button>
                <button onClick={addToExistingReview} className="mx-1">Ajouter à une revue de presse</button> 
                <button onClick={addToExistingFriendReview} className="mx-1">Ajouter à une revue de presse d'un ami</button> 
            </div>

            {
              filteredFavorites.map((fav, i) => (
                    <div className="featuresfav" key={i} id={`fav_${i}`}>
                        <div className="itemfav rounded-lg shadow m-2" data-id={i}>
                          <div  className="item-photowrapper">
                              <img
                                  className="item-photofav"
                                  src={helpers.htmlDecode(fav.image || process.env.PUBLIC_URL + "/news_icon2.jpg") /**  IMAGE  */}
                                  alt="article"
                                  onClick={() => window.open(helpers.htmlDecode(fav.link), "_blank")} 
                                />
                          </div>
                          <div className="arttfav">
                            <div className="item-headerfav">
                              
                            </div>
                            <a href={helpers.htmlDecode(fav.link)} target="_blank" rel="noreferrer" style={{ textDecoration: "inherit", color: "inherit" }}>
                              <h3 className="item-titlefav">
                                  {helpers.htmlDecode(fav.title) }
                              </h3>
                              <p><strong className="text-cyan-600">
                                  Pays : {helpers.htmlDecode(fav.country) } | Source : {helpers.getDomainName(fav.link) } 
                                </strong></p>  
                              <p className="item-textfav">
                                  {helpers.htmlDecode(fav.description) }
                              </p>
                            </a>
                            {/* <a className="item-link" href="#">Click to see by yourself &rarr;</a> */}
                            <div>
                              <div  className="inline-block mb-2">Présent dans : </div>
                            {Array.isArray(reviews) ? reviews.map(review => (
                                review.articles.filter(article => article.title === fav.title)
                                .map(filteredArticle => ( 
                                  <div key={filteredArticle.id} className="inline-block">
                                    <strong>{review.theme}</strong><span className="mx-1"> | </span>
                                  </div>
                                ))
                              )) : null} 
                            </div>

                          </div>

                          { true ? 
                          (
                            <div className="actiondiv">
                              <input type="checkbox" onClick={updateSelection} />
                              <span onClick={() => removeFav(i)} className="material-symbols-outlined" >
                                  delete
                              </span>
                            </div>
                          ) : 
                          (
                            <div className="favicon"><img src={Favo} alt="fav icon"/></div>
                          )
                          }
                        </div>
                    </div>
              ))
            }

        </section>
      </>
  );
};

export default ArticlesFav;
