import React, { useState, useEffect} from "react";
import "./Profile.css"
import verificato from "../../media/badge.png";
import userApi from "../../HTTP/user"
import ReviewBlocks from '../Review/ReviewBlocks'; // Assurez-vous que le chemin d'importation est correct
import { useParams } from 'react-router-dom';

const Friend = () => {
	let { id } = useParams(); // Assurez-vous que le nom du paramètre correspond à celui défini dans votre route 
	const [friend, setFriend] = useState([]);
	
	useEffect(() => {
		userApi.getFriend(id).then((f) => setFriend(f))
	})

	const [reviews, setReviews] = useState([]); 
	useEffect(() => {
		const fetchReviews = async () => {
		  const reviewsData = await userApi.getUserReviews(id); // Assurez-vous que ceci renvoie un tableau 
		  setReviews(reviewsData.reviews);
		};
	  
		fetchReviews();
	  }, []);
	 
	 

	return (
		<div>
		<div className="profileHeader md:flex mb-5">
			<div className="profileHeader-element">  
				<img src={friend.profile_picture || process.env.PUBLIC_URL+"/profile_default.png" } alt="profile" className="profilePicture" />  
			</div>
			<div className="profileHeader-element">  
			<h2> 
				{ `${friend?.firstname} ${friend?.lastname}` }
				<img className="verificato" src={verificato} alt="verification" />
			</h2>
				<div className="md:flex">
					<p><img src={process.env.PUBLIC_URL+"/sidebar/tel.png"} alt="Phone" className="profilePicto"  />{ `${friend?.phone}` }</p>
					<p><img src={process.env.PUBLIC_URL+"/sidebar/mail_white.png"} alt="email" className="profilePicto" />{ `${friend?.email}` }</p>
				</div>
				<p><img src={process.env.PUBLIC_URL+"/sidebar/terre.png"} alt="Country" className="profilePicto"  />{ `${friend?.country}` }</p>
				<p><img src={process.env.PUBLIC_URL+"/sidebar/pin.png"} alt="Address" className="profilePicto"  />{ `${friend?.address}` } </p>    
			</div> 
			<div className="profileHeader-element">  
				<div className="mt-2 pl-5 pr-5 md:ml-5 border-l-2 border-white profileHeader-bio">
					<h3 className="font-bold mb-2">Biographie</h3>
					<p>{ `${friend?.bio}` } </p>
				</div>
			</div>
		</div>
		<div className='profileContainer'> 

			<h1 className="ml-4">Revues de presse</h1> 
			<ReviewBlocks reviews={reviews} edition="false" />
		</div>
		</div>
	)
}

export default Friend
