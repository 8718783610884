

/**
 * @arg {string} text
 * Decodes the urls which have characters as HTML entities.
 * @returns {string}
**/
export function htmlDecode (text) {
    var textArea = document.createElement('textarea')
    textArea.innerHTML = text
    return textArea.value
}

// Fonction pour tronquer une chaîne de caractères
export const truncateString = (str, num) => {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + '...';
  };

// Fonction pour vérifier qu'une url est dans un tableau
export function checkLink(array, url) {
      function decodeHtmlEntities(encodedString) {
        var replacements = {
            "&#x2F;": "/",
            // Ajoutez d'autres remplacements d'entités HTML ici si nécessaire
        };

        return encodedString.replace(/&#x2F;|&#x2F;/gi, function(matched){
            return replacements[matched];
        });
    }
    // Décoder l'URL pour la comparaison
    const decodedUrl = decodeURIComponent(url); 
    return array.some(element => decodeHtmlEntities(element.link) === decodedUrl);
}

// Fonction pour décoder des entités HTML
export function decodeHtmlEntities(encodedString) {
  const replacements = {
    "&#x2F;": "/",
    "&#x27;": "'",
    "&amp;#x2F;": "/",
    // Ajoutez d'autres remplacements d'entités HTML ici si nécessaire
  };
  
  return encodedString.replace(/&#x2F;|&#x27;|&amp;#x2F;/gi, function(matched) {
    return replacements[matched];
  });
  
}

// Fonction pour retirer une URL dans un tableau
export function removeLink(array, url) {
  // Décoder l'URL pour la comparaison
  const decodedUrl = decodeURIComponent(url); 
  return array.filter(element => decodeHtmlEntities(element.link) !== decodedUrl);
}

export function formatDate(dateString) {
  const date = new Date(dateString);

  // Récupération des composants de la date
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Les mois en JavaScript commencent à 0
  const year = date.getFullYear();

  // Formatage de la date au format DD-MM-YYYY
  return `${day}-${month}-${year}`;
}

export function getDomainName(url) {
  try {
    const urlObj = new URL(url);
    return urlObj.hostname; // Retourne le nom de domaine
  } catch (error) {
    console.error("Erreur lors de l'extraction du nom de domaine:", error);
    return null; // ou une chaîne vide '' selon ce qui est préférable pour votre cas d'utilisation
  }
}