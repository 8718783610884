import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import "./Review.css" 
import { getReviewById, updateReview, removeArticle } from "../../HTTP/articles"
import * as helpers from "../../Helper/utils"
const user = JSON.parse(localStorage.getItem("user"))

const Review = () => {
	let { reviewid } = useParams(); // Assurez-vous que le nom du paramètre correspond à celui défini dans votre route 
	const [review, setReview] = useState([]); 
	  useEffect(() => {
      const fetchReview = async () => {
        const reviewData = await getReviewById(reviewid); // Assurez-vous que ceci renvoie un tableau
        setReview(reviewData);
      };
	  
		  fetchReview();
	  }, []);

	const handleSubmit = (e) => {
		e.preventDefault();
		// Traiter les données du formulaire, comme envoyer à une API
		try { 
			updateReview(review);
			alert('Revue de presse mise à jour !')
		} catch (error) {
			console.error('Erreur lors de la mise à jour', error);
		}
	};

	const onRemoveArticle = (articleId) => { 
		try { 
			removeArticle(articleId);
			const updatedArticles = review.articles.filter(article => article.id !== articleId);
  			setReview({...review, articles: updatedArticles});
			alert('Article retiré de la revue de presse !')
		} catch (error) {
			console.error('Erreur lors de la mise à jour', error);
		}
	};

	return (
		<div>
			{(typeof review === 'object' && review.user_id == user.id) ? (
				Array.isArray(review.articles) ?
				<> 
					<div className="review w-full">
						<div className="review-block bg-white rounded-lg shadow m-2">
							<button onClick={() => window.history.back()} className="backbutton bg-cyan-500 rounded-xl font-semibold py-1 px-4 absolute">
								← Retour
							</button>
							<div className="review-header p-4 block">
								<form onSubmit={handleSubmit}>
									<p><strong className="block text-uppercase mt-3">Edition de la revue de presse</strong></p>
									<input 
										className="block text-uppercase mb-2 p-2 w-full text-gray-500"
										value={review.theme} 
										onChange={(e) => setReview({...review, theme: e.target.value})}
										name="theme"
										maxLength="255"
									/>
									<textarea 
										className="block w-full p-2 text-gray-500"
										value={review.presentation} 
										onChange={(e) => setReview({...review, presentation: e.target.value})}
										rows="4"
										name="presentation"
										maxLength="255"
									/>
									<div className="block">
									<input type="checkbox" id="share_article_friends" checked={review.sharefriends} onChange={(e) => setReview({...review, sharefriends: e.target.checked})}/>
									<label htmlFor="share_article_friends"> J’autorise mes amis à publier dans cette revue de presse</label>
									</div>
									<button
										className="mt-4 bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded"
										type="submit"
									>
										Valider
									</button>
								</form>
							</div>
							<div className="review-inner p-4">
								<div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4  xl:grid-cols-6 gap-4"> 
									{review.articles && review.articles.map((article, articleIndex, { length }) => {
											let colSpan = "col-span-1";
											let heightSpan = "w-full h-48 object-cover rounded";
											if (length === 3 && articleIndex === 0) { // Premier article prend deux colonnes s'il y a trois articles
											colSpan = "md:col-span-1";
											heightSpan = "w-full h-48 object-cover rounded"
											} else if (length === 4) { // Chaque article prend exactement un quart de l'espace
											colSpan = "md:col-span-1";
											heightSpan = "w-full h-48 object-cover rounded"
											}

											return (
											<div key={articleIndex} className={`${colSpan}`}>
												<div className="article-content bg-white rounded-lg shadow relative">
													<img src={ helpers.htmlDecode(article.image || process.env.PUBLIC_URL + "/news_icon2.jpg") /**  IMAGE  */} alt={article.title} className={`${heightSpan}`} /> 
													<div className="article-title p-2">
													<h3 className="py-1">{article.title} </h3>
													<span className="article-origin block"><strong>{article.country}</strong> | {helpers.getDomainName(article.link)} </span>
													</div>
													<a href="#" 
														onClick={() => onRemoveArticle(article.id)}
														className="bg-red-500 hover:bg-red-700 text-white py-2 px-4 rounded-full inline-flex items-center inline-block mb-2 ">
														<svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
															<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
														</svg> 
													</a>
												</div>
											</div>
											);
										})}
								</div> 
							</div> 
							<div className="review-footer">
								<a href={`/reviews/${reviewid}/addfavourites`} className="block text-center m-4 bg-cyan-500 hover:bg-cyan-700 text-white font-bold py-2 px-4 rounded">Ajouter de nouveaux articles à cette revue de presse</a>
							</div>
						</div>
					</div>
				</> : <div className="bg-red-500 text-white p-4 rounded-lg shadow-md" role="alert"> 
						<span className="block sm:inline">Vous ne pouvez pas éditer cette revue de presse</span>
					</div>
			) :
				<div>
					<div className="bg-red-500 text-white p-4 rounded-lg shadow-md" role="alert"> 
						<span className="block sm:inline">Vous ne pouvez pas éditer cette revue de presse</span>
					</div>
				</div>
			}
		</div>
	)
}

export default Review
