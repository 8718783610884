import React from 'react';
import * as helpers from "../../Helper/utils"
import "./ReviewBlocks.css"
const ArticleBlocks = ({ articles }) => {
   
  if (!Array.isArray(articles)) {
    console.error('articles doit être un tableau', typeof articles);
    return null; // Ou retourner un fallback UI
  }

  
  return (
    <div className="reviews-container flex flex-wrap m-2"> 
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
          {articles && articles.map((article, articleIndex, { length }) => {
              let colSpan = "col-span-1";
              let heightSpan = "w-full h-24 object-cover rounded";
              if (length === 3 && articleIndex === 0) { // Premier article prend deux colonnes s'il y a trois articles
                colSpan = "md:col-span-2";
                heightSpan = "w-full h-24 object-cover rounded"
              } else if (length === 4) { // Chaque article prend exactement un quart de l'espace
                colSpan = "md:col-span-1";
                heightSpan = "w-full h-24 object-cover rounded"
              }

              return (
                <div key={articleIndex} className={`${colSpan}`}>
                  <div className="article-content bg-white rounded-lg shadow relative">
                    <a href={ helpers.htmlDecode(article.link)} target="blank">
                      <img src={ helpers.htmlDecode(article.image || process.env.PUBLIC_URL + "/news_icon2.jpg") /**  IMAGE  */} alt={article.title} className={`${heightSpan}`} /> 
                      <div className="article-title p-2">
                        <h3 className="py-1">{article.title} </h3>
                        <span className="article-origin block"><strong>{article.country}</strong> | {helpers.getDomainName(article.link)} </span>
                      </div>
                    </a>
                  </div>
                </div>
              );
            })}
        </div>  
    </div> 
  );
};

export default ArticleBlocks;
