import React, { useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
// import ListSubheader from '@mui/material/ListSubheader';
import Switch from '@mui/material/Switch';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import userApi from "../../HTTP/user"

export default function SwitchList() {
    const user = JSON.parse(localStorage.getItem("user"));
    // Utiliser la propriété 'private' pour initialiser l'état du switch
    const [isPrivate, setIsPrivate] = useState(!!user.private);
  
    const handleToggle = () => {
      // Appeler l'API pour mettre à jour le statut 'private'
      const newPrivateStatus = !isPrivate; 
      userApi.updatePrivacySetting(user.id).then(() => {
        const updatedUser = { ...user, private: newPrivateStatus };
        localStorage.setItem("user", JSON.stringify(updatedUser));
        setIsPrivate(newPrivateStatus);
      }).catch(error => {
        // Gérer les erreurs ici
        console.error('Erreur lors de la mise à jour des paramètres de confidentialité:', error);
      });
    };

  return (
    <List
      sx={{ width: '100%', maxWidth: 360, bgcolor: 'transparent' }} 
    >
      <ListItem>
        <ListItemIcon>
          <PrivacyTipIcon />
        </ListItemIcon>
        <ListItemText id="switch-list-label-privacytip" primary="Profil privé" />
        <Switch
          edge="end"
          onChange={handleToggle}
          checked={isPrivate}
          inputProps={{
            'aria-labelledby': 'switch-list-label-privacytip',
          }}
        />
      </ListItem> 
    </List>
  );
}