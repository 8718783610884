import React from 'react'
import "./suivis.css"
import star from "../../media/star.png"
import startred from "../../media/startred.png"
import thema from "../../media/ICONS THEMATIQUE/Fichier 5_1.png"
import amis from "../../media/ICONS THEMATIQUE/Fichier2.png"
import security from "../../media/ICONS THEMATIQUE/security.png"
import monde from "../../media/ICONS THEMATIQUE/monde.png"
import culture from "../../media/ICONS THEMATIQUE/Fichier 3.png"
import vivre from "../../media/ICONS THEMATIQUE/Fichier 4.png"
import z from "../../media/z.png"
import { countries } from "../../global/countriesAndSources"


const Suivis = () => {
  return (
    <div style={{ display: "flex" }}>
      <div className='country'>
        <ul className="ulflag">
          <div className="divliinput"> <li>  <input id="country_search" placeholder="Rechercher" /> </li></div>
          {
          countries.map((c, i) => 
            <div className="divli" key="i"> 
              <li>    
                <img className='imgContry' src={process.env.PUBLIC_URL + "/flags/" + c.code + ".svg"} alt="Country" /><a href="#">{c.name}</a>
              </li>
            </div>
          )
        }
        </ul>
        <div className='divvalid1' style={{ textAlign: "center" }}>
          <button className='Valider'>Valider </button>    
        </div>
      </div>
      
      <div className='thematcontainer'>

        <div className='sourcecontan'>
          <h1>Thématiques :</h1>

          <div className="themadiv">



            <div className='thema'>
              <div style={{ marginLeft: "27px", display: "flex", alignItems: "center" }}><img src={thema} alt="thema" />    <h2 style={{ marginLeft: "10px" }} >Chroniques</h2>  </div>
              <ul className="category-list">
                <li> <img src={star} alt="start" /> Politique Choix Élections</li>
                <li> <img src={startred} alt="red star"/> Économie Finance</li>
                <li> <img src={startred} alt="red star" /> Contrats Investissements</li>
                <li> <img src={star} alt="red"/> Affaires étrangères Visite</li>
                <li> <img src={startred} alt="red star" /> Fête Commémoration</li>
                <li> <img src={star} alt="red" /> Théâtre Concert Exposition</li>
              </ul>

            </div>
            <div className='thema'>
              <div style={{ marginLeft: "27px", display: "flex", alignItems: "center" }}><img src={amis} alt="friends" />    <h2 style={{ marginLeft: "10px" }} >Societé</h2>  </div>
              <ul className="category-list">
                <li> <img src={star} alt="star"/>Éthique Science High Tech</li>
                <li> <img src={star} alt="star" />Sport Handicap Dopage</li>
                <li> <img src={star} alt="star" />Stage Volontariat Retraite</li>
                <li> <img src={star} alt="star" />Travail Maladies Abus</li>
                <li> <img src={star} alt="star" />Revendication Manifestation</li>
                <li> <img src={star} alt="star" />TV Polémique Scandale</li>
                <li> <img src={star} alt="star" />Préjugés Rumeur Révélation</li>
                <li> <img src={star} alt="star" />Gouvernance Ingérenc</li>
              </ul>

            </div>


            <div className='thema'>
              <div style={{ marginLeft: "27px", display: "flex", alignItems: "center" }}><img src={security} alt="security" />    <h2 style={{ marginLeft: "10px" }} >Planéte</h2>  </div>
              <ul className="category-list">

                <li> <img src={star} alt="star" />Police Justice Insécurité</li>
                <li> <img src={star} alt="star" />Alerte Signalement Délit</li>
                <li> <img src={star} alt="star" />Surveillance Écoute Secret</li>
                <li> <img src={star} alt="star" />Terrorisme Menace Paix</li>
                <li> <img src={star} alt="star" />Tension Conflit Expatriés</li>
                <li> <img src={star} alt="star" />Opération militaire Guerre</li>



              </ul>

            </div>

            <div className='thema'>
              <div style={{ marginLeft: "27px", display: "flex", alignItems: "center" }}><img src={monde} alt="world" />    <h2 style={{ marginLeft: "10px" }} >Vivre ensemble</h2>  </div>
              <ul className="category-list">
                <li> <img src={star} alt="star" />Police Justice Insécurité</li>
                <li> <img src={star} alt="star" />Alerte Signalement Délit</li>
                <li> <img src={star} alt="star" />Surveillance Écoute Secret</li>
                <li> <img src={star} alt="star" />Terrorisme Menace Paix</li>
                <li> <img src={star} alt="star" />Tension Conflit Expatriés</li>
                <li> <img src={star} alt="star" />Opération militaire Guerre</li>
              </ul>

            </div>



            <div className='thema'>
              <div style={{ marginLeft: "27px", display: "flex", alignItems: "center" }}><img src={culture} alt="culture" />    <h2 style={{ marginLeft: "10px" }} >Culture</h2>  </div>
              <ul className="category-list">

                <li> <img src={star} alt="star" />Éducation Scolarisation</li>
                <li> <img src={star} alt="star" />Archéologie Patrimoine</li>
                <li> <img src={star} alt="star" />Préhistoire Civilisation</li>
                <li> <img src={star} alt="star" />Histoire Hommage Héros</li>
                <li> <img src={star} alt="star" />Cuisine Astuce Bricolage</li>
                <li> <img src={star} alt="star" />Mode Hommes Femmes</li>
                <li> <img src={star} alt="star" />Loisirs Voyage Tourisme</li>

              </ul>

            </div>


            <div className='thema'>
              <div style={{ marginLeft: "27px", display: "flex", alignItems: "center" }}><img src={vivre} alt="vivre" />    <h2 style={{ marginLeft: "10px" }} >Vivre ensemble</h2>  </div>
              <ul className="category-list">

                <li> <img src={star} alt="star" />Interreligieux Minorités</li>
                <li> <img src={star} alt="star" />Diaspora Immigrés Visa</li>
                <li> <img src={star} alt="star" />Bi-nationalité Apatride</li>
                <li> <img src={star} alt="star" />Amitié Mariage Insolite</li>
                <li> <img src={star} alt="star" />Discrimination Sexisme</li>
                <li> <img src={star} alt="star" />Vie Habitudes Mœurs</li>
                <li> <img src={star} alt="star" />Réputation Crise Identité</li>


              </ul>

            </div>





          </div>

        </div>
        <div style={{ textAlign: "right" }}>     <button style={{ marginRight: "15px", top: '15px' }} className='Valider'>Valider </button>   </div>

      </div>

      <div className='source'>
        <h2>Sources :
        </h2>

        <div>
          <ul className="source-list">
            <li> <img src={star} alt="star" />TV</li>
            <li> <img src={star} alt="star" />Agences de presse</li>
            <li> <img src={star} alt="star" />Pouvoirs publiques</li>
            <li> <img src={star} alt="star" />Sécurité Nationale</li>
            <li> <img src={star} alt="star" />Ambassades</li>
            <li> <img src={star} alt="star" />Partis politiques</li>
            <li> <img src={star} alt="star" />Think Tanks</li>
            <li> <img src={star} alt="star" />Universités</li>
            <li> <img src={star} alt="star" />Grandes entreprises</li>
          </ul> </div>
        <div className='Valider3'>  <button className='Valider'>Valider </button>    </div>
      </div>

      <div className='sourcecontan'>

        <h1>Recapitulatif :</h1>
        <div className='reca-container'>

          <div className='reca'>
            <div className='recaflex'>
              <div className='imgz'>
                <img src={z} alt="z" />
              </div>

              <div className='infocontry'>
                <p className='contryname'>Italie</p>

                <p>Source :</p>
                <ul>
                  <li>• Journaux</li>
                  <li>• Agences de presse</li>
                  <li>• TV</li>
                </ul>


                <div>
                  <p className='subname'>Thématique :</p>
                  <ul>
                    <li>• Politique</li>

                  </ul>
                </div>

              </div>
              <div className='imgz'><span className="material-symbols-outlined">delete</span> </div>

            </div>

            <div className='recaflex'>
              <div className='imgz'>
                <img src={z} alt="z" />
              </div>

              <div className='infocontry'>
                <p className='contryname'>Italie</p>

                <p>Source :</p>
                <ul>
                  <li>• Journaux</li>
                  <li>• Agences de presse</li>
                  <li>• TV</li>
                </ul>


                <div>
                  <p className='subname'>Thématique :</p>
                  <ul>
                    <li>• Politique</li>

                  </ul>
                </div>

              </div>
              <div className='imgz'><span className="material-symbols-outlined">delete</span> </div>

            </div>

            <div className='recaflex'>
              <div className='imgz'>
                <img src={z} alt="z" />
              </div>

              <div className='infocontry'>
                <p className='contryname'>Italie</p>

                <p>Source :</p>
                <ul>
                  <li>• Journaux</li>
                  <li>• Agences de presse</li>
                  <li>• TV</li>
                </ul>


                <div>
                  <p className='subname'>Thématique :</p>
                  <ul>
                    <li>• Politique</li>

                  </ul>
                </div>

              </div>
              <div className='imgz'><span className="material-symbols-outlined">delete</span> </div>

            </div>


          </div>

        </div>



      </div>








    </div>
  )
}

export default Suivis