export const countries = [
  {"name": "Afghanistan", "code": "af", "cse": "018091190003298728883:v1cpngmakjm"},
  {"name": "Albanie", "code": "al", "cse": "018091190003298728883:1rilpwdfzj8"},
  {"name": "Algérie", "code": "dz", "cse": "018091190003298728883:ww7ppunocsm"},
  {"name": "Andorre", "code": "ad", "cse": "018091190003298728883:dkicqn8kgyq"},
  {"name": "Angola", "code": "ao", "cse": "018091190003298728883:9ihhyhyv7ck"},
  {"name": "Antigua-et-Barbuda", "code": "ag", "cse": "018091190003298728883:asq6urugsug"},
  {"name": "Argentine", "code": "ar", "cse": "018091190003298728883:_h6lhibqux0"},
  {"name": "Arménie", "code": "am", "cse": "018091190003298728883:l0ep7e96qa8"},
  {"name": "Australie", "code": "au", "cse": "004899679196972058950:vcvmuqp3x2k"},
  {"name": "Autriche", "code": "at", "cse": "018091190003298728883:ntnu5_8xxrc"},
  {"name": "Azerbaïdjan", "code": "az", "cse": "018091190003298728883:yce8u3rqsly"},
  {"name": "Bahamas", "code": "bs", "cse": "018091190003298728883:a2czzvktoqy"},
  {"name": "Bahreïn", "code": "bh", "cse": "018091190003298728883:3dxnoyip0b8"},
  {"name": "Bangladesh", "code": "bd", "cse": "018091190003298728883:hjvojdxsz0c"},
  {"name": "Barbade", "code": "bb", "cse": "018091190003298728883:lyz2wltp57c"},
  {"name": "Biélorussie", "code": "by", "cse": "017907537962060352927%3Aktliaoxmows"},
  {"name": "Belgique", "code": "be", "cse": "018091190003298728883:7wix4cvn8ey"},
  {"name": "Belize", "code": "bz", "cse": "018091190003298728883:iqcass3nygc"},
  {"name": "Bénin", "code": "bj", "cse": "018091190003298728883:-d2o_jio5dm"},
  {"name": "Bhoutan", "code": "bt", "cse": "018091190003298728883:by_amx9kom8"},
  {"name": "Bolivie", "code": "bo", "cse": "018091190003298728883:6w1ew9mqlya"},
  {"name": "Bosnie-Herzégovine", "code": "ba", "cse": "017907537962060352927%3Awdzmj7005_e"},
  {"name": "Botswana", "code": "bw", "cse": "018091190003298728883:orjm-a8ck-u"},
  {"name": "Brésil", "code": "br", "cse": "004899679196972058950:exmb_qdc6tw"},
  {"name": "Brunei", "code": "bn", "cse": "018091190003298728883:djsfapfipho"},
  {"name": "Bulgarie", "code": "bg", "cse": "018091190003298728883:97qdbezsh2w"},
  {"name": "Burkina Faso", "code": "bf", "cse": "018091190003298728883:bk4jhwznutm"},
  {"name": "Burundi", "code": "bi", "cse": "018091190003298728883:4lnkexsjbzq"},
  {"name": "Cambodge", "code": "kh", "cse": "018091190003298728883:nqbdp7uoa4i"},
  {"name": "Cameroun", "code": "cm", "cse": "018091190003298728883:zmc6j4sztng"},
  {"name": "Canada", "code": "ca", "cse": "018091190003298728883:mo81hr8loza"},
  {"name": "Cap-Vert", "code": "cv", "cse": "018091190003298728883:rrtme-omq5a"},
  {"name": "République centrafricaine", "code": "cf", "cse": "005897242965503679309:eccjptfb3vo"},
  {"name": "Tchad", "code": "td", "cse": "013581124321713960683:bltrtkbndy4"},
  {"name": "Chili", "code": "cl", "cse": "004899679196972058950:5h6rq3vugom"},
  {"name": "Chine", "code": "cn", "cse": "018091190003298728883:gyaxcjs9fiw"},
  {"name": "Colombie", "code": "co", "cse": "011721385535203573713:qxam5emfhba"},
  {"name": "Comores", "code": "km", "cse": "011721385535203573713:bt84byokbne"},
  {"name": "Congo-Brazzaville", "code": "cg", "cse": "011721385535203573713:zfy7kgohoha"},
  {"name": "Congo-Kinshasa", "code": "cd", "cse": "011721385535203573713:zfy7kgohoha"},
  {"name": "Îles Cook", "code": "ck", "cse": "003393126044876575554:bjbliszn2ym"},
  {"name": "Costa Rica", "code": "cr", "cse": "011721385535203573713:twstqbmnxve"},
  {"name": "Côte d'Ivoire", "code": "ci", "cse": "011721385535203573713:ickrhctedmu"},
  {"name": "Croatie", "code": "hr", "cse": "011721385535203573713:ls3onyjx3wy"},
  {"name": "Cuba", "code": "cu", "cse": "011721385535203573713:pmjoqrjehnk"},
  {"name": "Chypre", "code": "cy", "cse": "018091190003298728883:gcnwgtppitu"},
  {"name": "République tchèque", "code": "cz", "cse": "005897242965503679309:dkvw0lwq1gq"},
  {"name": "Danemark", "code": "dk", "cse": "011721385535203573713:3i9anhuanzw"},
  {"name": "Djibouti", "code": "dj", "cse": "011721385535203573713:xsk4xbsgvns"},
  {"name": "Dominique", "code": "dm", "cse": "005897242965503679309:_ykks3dfjeq"},
  {"name": "République dominicaine", "code": "do", "cse": "005897242965503679309:_ykks3dfjeq"},
  {"name": "Équateur", "code": "ec", "cse": "011721385535203573713:t_ub2er4few"},
  {"name": "Égypte", "code": "eg", "cse": "011721385535203573713:u7nzy7ff3qo"},
  {"name": "Salvador", "code": "sv", "cse": "011721385535203573713:5zbumni2364"},
  {"name": "Guinée équatoriale", "code": "gq", "cse": "011721385535203573713:2ejhdjpdhuy"},
  {"name": "Érythrée", "code": "er", "cse": "011721385535203573713:svbcqvhh8ge"},
  {"name": "Estonie", "code": "ee", "cse": "011721385535203573713:be69cyaqvk4"},
  {"name": "Eswatini", "code": "sz", "cse": "013581124321713960683:vihapujwjiw"},
  {"name": "Éthiopie", "code": "et", "cse": "011721385535203573713:78w1fykyw0e"},
  {"name": "Union européenne", "code": "ue", "cse": "018091190003298728883:4f0plk-khh0"},
  {"name": "Fidji", "code": "fj", "cse": "011721385535203573713:c2fri5bqzla"},
  {"name": "Finlande", "code": "fi", "cse": "011721385535203573713:xob6c7lcjca"},
  {"name": "France", "code": "fr", "cse": "004899679196972058950:gvpguoasgrm "},
  {"name": "Gabon", "code": "ga", "cse": "011721385535203573713:z7bqzjhitqq"},
  {"name": "Gambie", "code": "gm", "cse": "011721385535203573713:chgrd26rb48"},
  {"name": "Géorgie", "code": "ge", "cse": "011721385535203573713:j2fwzpytybk"},
  {"name": "Allemagne", "code": "de", "cse": "004899679196972058950:-pvhdtnf0og"},
  {"name": "Ghana", "code": "gh", "cse": "011721385535203573713:lbg6zzkfvro"},
  {"name": "Grèce", "code": "gr", "cse": "011721385535203573713:kwyibaxzov4"},
  {"name": "Grenade", "code": "gd", "cse": "011721385535203573713:zxzoouczkv0"},
  {"name": "Guatemala", "code": "gt", "cse": "011721385535203573713:hd9p85fll8a"},
  {"name": "Guinée", "code": "gn", "cse": "011721385535203573713:zjn8mmtqqu8"},
  {"name": "Guinée-Bissau", "code": "gw", "cse": "011721385535203573713:wa34-fri3-g"},
  {"name": "Guyana", "code": "gy", "cse": "011721385535203573713:lz_1b2j86li"},
  {"name": "Haïti", "code": "ht", "cse": "011721385535203573713:h9s0oret3s0"},
  {"name": "Honduras", "code": "hn", "cse": "011721385535203573713:gaoa1y8xii4"},
  {"name": "Hongrie", "code": "hu", "cse": "011721385535203573713:e2kgpuibibo"},
  {"name": "Islande", "code": "is", "cse": "003393126044876575554:sl8jouqd_y0"},
  {"name": "Inde", "code": "in", "cse": "003393126044876575554:yf5eis4jsmk"},
  {"name": "Indonésie", "code": "id", "cse": "003393126044876575554:cwxw8fkhcdg"},
  {"name": "Iran", "code": "ir", "cse": "003393126044876575554:vron4f2xjmk"},
  {"name": "Irak", "code": "iq", "cse": "003393126044876575554:vtdddhs2yrw"},
  {"name": "Irlande", "code": "ie", "cse": "003393126044876575554:am0vthycdfc"},
  {"name": "Israël", "code": "il", "cse": "003393126044876575554:adpv4eamiew"},
  {"name": "Italie", "code": "it", "cse": "003393126044876575554:smyjgicmgy8"},
  {"name": "Jamaïque", "code": "jm", "cse": "003393126044876575554:s53w1pi7vpc"},
  {"name": "Japon", "code": "jp", "cse": "003393126044876575554:yfcykx3nt60"},
  {"name": "Jordanie", "code": "jo", "cse": "003393126044876575554:p6gx2matabe"},
  {"name": "Kazakhstan", "code": "kz", "cse": "003393126044876575554:kjbxj0p2fbo"},
  {"name": "Kenya", "code": "ke", "cse": "003393126044876575554:8iqa72qhb1y"},
  {"name": "Kiribati", "code": "ki", "cse": "003393126044876575554:ahh7tsnx-0y"},
  {"name": "Kosovo", "code": "xk", "cse": "018091190003298728883:yvqqqyu08iz"},
  {"name": "Koweït", "code": "kw", "cse": "003393126044876575554:sywi6c4h3di"},
  {"name": "Kirghizistan", "code": "kg", "cse": "003393126044876575554:8fr6vlevptc"},
  {"name": "Laos", "code": "la", "cse": "003393126044876575554:_lioxqivaeo"},
  {"name": "Lettonie", "code": "lv", "cse": "003393126044876575554:vawimvzi168"},
  {"name": "Liban", "code": "lb", "cse": "003393126044876575554:5exylvyiybg"},
  {"name": "Lesotho", "code": "ls", "cse": "003393126044876575554:nwuk9okqei8"},
  {"name": "Liberia", "code": "lr", "cse": "016168714383393267599:xedyseq2mry"},
  {"name": "Libye", "code": "ly", "cse": "016168714383393267599:hhxlfzvbw9m"},
  {"name": "Liechtenstein", "code": "li", "cse": "016168714383393267599:byobmtqaacq"},
  {"name": "Lituanie", "code": "lt", "cse": "016168714383393267599:kdcpitmqqho"},
  {"name": "Luxembourg", "code": "lu", "cse": "016168714383393267599:uytgzlwlv2e"},
  {"name": "Madagascar", "code": "mg", "cse": "016168714383393267599:kerq0clw9wq"},
  {"name": "Malawi", "code": "mw", "cse": "005897242965503679309:v6ak4trrld4"},
  {"name": "Malaisie", "code": "my", "cse": "005897242965503679309:0endly-ozeu"},
  {"name": "Maldives", "code": "mv", "cse": "005897242965503679309:qsjxdwmzqfg"},
  {"name": "Mali", "code": "ml", "cse": "005897242965503679309:kol689qztge"},
  {"name": "Malte", "code": "mt", "cse": "005897242965503679309:dwc_aancpkc"},
  {"name": "Îles Marshall", "code": "mh", "cse": "003393126044876575554:sbkmgwkpe60"},
  {"name": "Mauritanie", "code": "mr", "cse": "017907537962060352927%3Aljb3za8tzcu"},
  {"name": "Maurice", "code": "mu", "cse": "003393126044876575554:4h1v7mmedri"},
  {"name": "Mexique", "code": "mx", "cse": "005897242965503679309:5cpfca4ymri"},
  {"name": "Micronésie", "code": "fm", "cse": "005897242965503679309:p4wvpgfcxba"},
  {"name": "Moldavie", "code": "md", "cse": "005897242965503679309:cxukr3k7j90"},
  {"name": "Monaco", "code": "mc", "cse": "005897242965503679309:jbl_ps4ne3m"},
  {"name": "Mongolie", "code": "mn", "cse": "005897242965503679309:_do-htmigcq"},
  {"name": "Monténégro", "code": "me", "cse": "005897242965503679309:csri_1gl7qw"},
  {"name": "Maroc", "code": "ma", "cse": "005897242965503679309:ccj366jtaew"},
  {"name": "Mozambique", "code": "mz", "cse": "005897242965503679309%3Anwqqesxuznc"},
  {"name": "Myanmar (Birmanie)", "code": "mm", "cse": "018091190003298728883:yhedvrjiavq"},
  {"name": "Namibie", "code": "na", "cse": "005897242965503679309:xh4x2eyktau"},
  {"name": "Nauru", "code": "nr", "cse": "005897242965503679309:ak7vw7jm2ke"},
  {"name": "Népal", "code": "np", "cse": "005897242965503679309:b9t6gehyude"},
  {"name": "Pays-Bas", "code": "nl", "cse": "005897242965503679309:j6hgy7nfw0i"},
  {"name": "Nouvelle-Zélande", "code": "nz", "cse": "005897242965503679309:s6mud4eyytw"},
  {"name": "Nicaragua", "code": "ni", "cse": "005897242965503679309:sk_t0nva_44"},
  {"name": "Niger", "code": "ne", "cse": "005897242965503679309:ed1dq2gpmuc"},
  {"name": "Nigeria", "code": "ng", "cse": "005897242965503679309:a4ju7v1dcbm"},
  {"name": "Niue", "code": "nu", "cse": "017907537962060352927:pi-i0t63bzu"},
  {"name": "Corée du Nord", "code": "kp", "cse": "011721385535203573713:6r2svpn9k0c"},
  {"name": "Macédoine du Nord", "code": "mk", "cse": "016168714383393267599:63sr7mwwnmq"},
  {"name": "Norvège", "code": "no", "cse": "005897242965503679309:lyldticgcgm"},
  {"name": "Oman", "code": "om", "cse": "005897242965503679309:bzeb7kcdmho"},
  {"name": "Pakistan", "code": "pk", "cse": "005897242965503679309:rnh2govxwgy"},
  {"name": "Palaos", "code": "pw", "cse": "005897242965503679309:fav4t_4qdhk"},
  {"name": "Territoires palestiniens", "code": "ps", "cse": "005897242965503679309:k8bx6f_sfhu"},
  {"name": "Panama", "code": "pa", "cse": "005897242965503679309:xs5mv0i4dnu"},
  {"name": "Papouasie-Nouvelle-Guinée", "code": "pg", "cse": "005897242965503679309:hxuq2dgrkz4"},
  {"name": "Paraguay", "code": "py", "cse": "005897242965503679309:6zms2ogtsjq"},
  {"name": "Pérou", "code": "pe", "cse": "005897242965503679309:ausdf-utvge"},
  {"name": "Philippines", "code": "ph", "cse": "005897242965503679309:8paro9li-bu"},
  {"name": "Pologne", "code": "pl", "cse": "005897242965503679309:majkgdmmola"},
  {"name": "Portugal", "code": "pt", "cse": "005897242965503679309%3Ah21knfri9ky"},
  {"name": "Qatar", "code": "qa", "cse": "005897242965503679309:ifvxbjg81-k"},
  {"name": "Roumanie", "code": "ro", "cse": "005897242965503679309:yugee8v8n0w"},
  {"name": "Russie", "code": "ru", "cse": "005897242965503679309:i6yyyanxc54"},
  {"name": "Rwanda", "code": "rw", "cse": "013581124321713960683:z9ipb1peabg"},
  {"name": "Samoa", "code": "ws", "cse": "017907537962060352927%3Aatujw_ktdtu"},
  {"name": "Saint-Marin", "code": "sm", "cse": "013581124321713960683:j98yujmlhzk"},
  {"name": "São Tomé-et-Principe", "code": "st", "cse": "013581124321713960683:cvzjaoboflu"},
  {"name": "Arabie saoudite", "code": "sa", "cse": "018091190003298728883:usfbvcyarr4"},
  {"name": "Sénégal", "code": "sn", "cse": "013581124321713960683:1v5xr6re7r0"},
  {"name": "Serbie", "code": "rs", "cse": "013581124321713960683:gjf3gkchnh0"},
  {"name": "Seychelles", "code": "sc", "cse": "013581124321713960683:xudx67hozt8"},
  {"name": "Sierra Leone", "code": "sl", "cse": "013581124321713960683:ls3g4xr3q8y"},
  {"name": "Singapour", "code": "sg", "cse": "013581124321713960683:nfmwxixqhgk"},
  {"name": "Slovaquie", "code": "sk", "cse": "013581124321713960683:hnez0atyrde"},
  {"name": "Slovénie", "code": "si", "cse": "013581124321713960683:sye2fdcggbe"},
  {"name": "Îles Salomon", "code": "sb", "cse": "003393126044876575554:u6efj7tyvmw"},
  {"name": "Somalie", "code": "so", "cse": "013581124321713960683:afim9hzowwc"},
  {"name": "Afrique du Sud", "code": "za", "cse": "018091190003298728883:b41hlozr-hg"},
  {"name": "Corée du Sud", "code": "kr", "cse": "011721385535203573713:o0y1ms1dtb8"},
  {"name": "Soudan du Sud", "code": "ss", "cse": "013581124321713960683:rszw96saamq"},
  {"name": "Espagne", "code": "es", "cse": "011721385535203573713:blcjji8uhbu"},
  {"name": "Sri Lanka", "code": "lk", "cse": "013581124321713960683:f2zvr-tlncu"},
  {"name": "Saint-Kitts-et-Nevis", "code": "kn", "cse": "013581124321713960683:zbbutv2jhva"},
  {"name": "Sainte-Lucie", "code": "lc", "cse": "013581124321713960683:rshhytzsmiw"},
  {"name": "Saint-Vincent-et-les-Grenadines", "code": "vc", "cse": "018091190003298728883:_pzodtq6bpo"},
  {"name": "Soudan", "code": "sd", "cse": "013581124321713960683:ac2rgl6g0b4"},
  {"name": "Suriname", "code": "sr", "cse": "013581124321713960683:bcr70a8rrvs"},
  {"name": "Suède", "code": "se", "cse": "013581124321713960683:3ua8thbhlwy"},
  {"name": "Suisse", "code": "ch", "cse": "013581124321713960683:emlwm4eibs8"},
  {"name": "Syrie", "code": "sy", "cse": "013581124321713960683:y4sxk-tia1s"},
  {"name": "Taïwan", "code": "tw", "cse": "013581124321713960683:xij-2q8akei"},
  {"name": "Tadjikistan", "code": "tj", "cse": "013581124321713960683:fok8u0i4_40"},
  {"name": "Tanzanie", "code": "tz", "cse": "013581124321713960683:diirynd1pdy"},
  {"name": "Thaïlande", "code": "th", "cse": "013581124321713960683:twrytb4x0x8"},
  {"name": "Timor oriental", "code": "tl", "cse": "017907537962060352927:bt576hfpsag"},
  {"name": "Togo", "code": "tg", "cse": "013581124321713960683:ahqs2-vf9mk"},
  {"name": "Tonga", "code": "to", "cse": "017907537962060352927:m9azw_x5zr0"},
  {"name": "Trinité-et-Tobago", "code": "tt", "cse": "013581124321713960683:za5k4kpb110"},
  {"name": "Tunisie", "code": "tn", "cse": "013581124321713960683:1mqjuiwqpks"},
  {"name": "Turquie", "code": "tr", "cse": "013581124321713960683:dh6jkcytji4"},
  {"name": "Turkménistan", "code": "tm", "cse": "013581124321713960683:ywwlemluwq4"},
  {"name": "Tuvalu", "code": "tv", "cse": "013581124321713960683:ezlk1fvjjr0"},
  {"name": "Ouganda", "code": "ug", "cse": "005897242965503679309:hgzrdhigfas"},
  {"name": "Ukraine", "code": "ua", "cse": "013581124321713960683:dn-rem_pyl4"},
  {"name": "Émirats arabes unis", "code": "ae", "cse": "011721385535203573713:ps-a49ir7qu"},
  {"name": "Royaume-Uni", "code": "gb", "cse": "005897242965503679309:lyciqtpz07q"},
  {"name": "États-Unis", "code": "us", "cse": "81ea303838d5c463a"},
  {"name": "Uruguay", "code": "uy", "cse": "013581124321713960683:nn3tblf-ohe"},
  {"name": "Ouzbékistan", "code": "uz", "cse": "005897242965503679309:njtv-zfft8a"},
  {"name": "Vanuatu", "code": "vu", "cse": "013581124321713960683:w4e2z1n_d5k"},
  {"name": "Cité du Vatican", "code": "va", "cse": "013581124321713960683:qgx2jqeha40"},
  {"name": "Venezuela", "code": "ve", "cse": "013581124321713960683:o0qwslzjjhg"},
  {"name": "Vietnam", "code": "vn", "cse": "013581124321713960683:welfoblgo0k"},
  {"name": "Yémen", "code": "ye", "cse": "013581124321713960683:ihi2hxbubl8"},
  {"name": "Zambie", "code": "zm", "cse": "013581124321713960683:r35a6vkfeo4"},
  {"name": "Zimbabwe", "code": "zw", "cse": "013581124321713960683:a0_cjumclhg"}
]

export const languages = [
    {
      "language_code": "fr",
      "language_name": "Français"
    },
    {
      "language_code": "ab",
      "language_name": "Abkhaze"
    },
    {
      "language_code": "ace",
      "language_name": "Aceh"
    },
    {
      "language_code": "ach",
      "language_name": "Acoli"
    },
    {
      "language_code": "aa",
      "language_name": "Afar"
    },
    {
      "language_code": "af",
      "language_name": "Afrikaans"
    },
    {
      "language_code": "sq",
      "language_name": "Albanais"
    },
    {
      "language_code": "de",
      "language_name": "Allemand"
    },
    {
      "language_code": "alz",
      "language_name": "Alur"
    },
    {
      "language_code": "am",
      "language_name": "Amharique"
    },
    {
      "language_code": "en",
      "language_name": "Anglais"
    },
    {
      "language_code": "ar",
      "language_name": "Arabe"
    },
    {
      "language_code": "hy",
      "language_name": "Arménien"
    },
    {
      "language_code": "as",
      "language_name": "Assamais"
    },
    {
      "language_code": "av",
      "language_name": "Avar"
    },
    {
      "language_code": "awa",
      "language_name": "Awadhi"
    },
    {
      "language_code": "ay",
      "language_name": "Aymara"
    },
    {
      "language_code": "az",
      "language_name": "Azerbaïdjanais"
    },
    {
      "language_code": "ba",
      "language_name": "Bachkir"
    },
    {
      "language_code": "ban",
      "language_name": "Balinais"
    },
    {
      "language_code": "bal",
      "language_name": "Baloutche"
    },
    {
      "language_code": "bm",
      "language_name": "Bambara"
    },
    {
      "language_code": "bci",
      "language_name": "Baoulé"
    },
    {
      "language_code": "eu",
      "language_name": "Basque"
    },
    {
      "language_code": "btx",
      "language_name": "Batak Karo"
    },
    {
      "language_code": "bts",
      "language_name": "Batak Simalungun"
    },
    {
      "language_code": "bbc",
      "language_name": "Batak Toba"
    },
    {
      "language_code": "bem",
      "language_name": "Bemba"
    },
    {
      "language_code": "bn",
      "language_name": "Bengali"
    },
    {
      "language_code": "bew",
      "language_name": "Betawi"
    },
    {
      "language_code": "bho",
      "language_name": "Bhojpuri"
    },
    {
      "language_code": "bik",
      "language_name": "Bikol"
    },
    {
      "language_code": "be",
      "language_name": "Biélorusse"
    },
    {
      "language_code": "my",
      "language_name": "Birman"
    },
    {
      "language_code": "bs",
      "language_name": "Bosnien"
    },
    {
      "language_code": "bua",
      "language_name": "Bouriate"
    },
    {
      "language_code": "br",
      "language_name": "Breton"
    },
    {
      "language_code": "bg",
      "language_name": "Bulgare"
    },
    {
      "language_code": "yue",
      "language_name": "Cantonais"
    },
    {
      "language_code": "ca",
      "language_name": "Catalan"
    },
    {
      "language_code": "ceb",
      "language_name": "Cebuano"
    },
    {
      "language_code": "ch",
      "language_name": "Chamorro"
    },
    {
      "language_code": "ny",
      "language_name": "Chewa"
    },
    {
      "language_code": "zh-CN",
      "language_name": "Chinois (simplifié)"
    },
    {
      "language_code": "zh-TW",
      "language_name": "Chinois (traditionnel)"
    },
    {
      "language_code": "chk",
      "language_name": "Chuuk"
    },
    {
      "language_code": "si",
      "language_name": "Cingalais"
    },
    {
      "language_code": "ko",
      "language_name": "Coréen"
    },
    {
      "language_code": "co",
      "language_name": "Corse"
    },
    {
      "language_code": "ht",
      "language_name": "Créole haïtien"
    },
    {
      "language_code": "mfe",
      "language_name": "Créole mauricien"
    },
    {
      "language_code": "crs",
      "language_name": "Créole seychellois"
    },
    {
      "language_code": "hr",
      "language_name": "Croate"
    },
    {
      "language_code": "da",
      "language_name": "Danois"
    },
    {
      "language_code": "fa-AF",
      "language_name": "Dari"
    },
    {
      "language_code": "din",
      "language_name": "Dinka"
    },
    {
      "language_code": "dyu",
      "language_name": "Dioula"
    },
    {
      "language_code": "dv",
      "language_name": "Divehi"
    },
    {
      "language_code": "doi",
      "language_name": "Dogri"
    },
    {
      "language_code": "dov",
      "language_name": "Dombe"
    },
    {
      "language_code": "dz",
      "language_name": "Dzongkha"
    },
    {
      "language_code": "es",
      "language_name": "Espagnol"
    },
    {
      "language_code": "eo",
      "language_name": "Espéranto"
    },
    {
      "language_code": "et",
      "language_name": "Estonien"
    },
    {
      "language_code": "ee",
      "language_name": "Éwé"
    },
    {
      "language_code": "fo",
      "language_name": "Féroïen"
    },
    {
      "language_code": "fj",
      "language_name": "Fidjien"
    },
    {
      "language_code": "fi",
      "language_name": "Finnois"
    },
    {
      "language_code": "fon",
      "language_name": "Fon"
    },
    {
      "language_code": "fur",
      "language_name": "Frioulan"
    },
    {
      "language_code": "fy",
      "language_name": "Frison"
    },
    {
      "language_code": "gaa",
      "language_name": "Ga"
    },
    {
      "language_code": "gd",
      "language_name": "Gaélique écossais"
    },
    {
      "language_code": "gl",
      "language_name": "Galicien"
    },
    {
      "language_code": "cy",
      "language_name": "Gallois"
    },
    {
      "language_code": "ka",
      "language_name": "Géorgien"
    },
    {
      "language_code": "el",
      "language_name": "Grec"
    },
    {
      "language_code": "gn",
      "language_name": "Guarani"
    },
    {
      "language_code": "gu",
      "language_name": "Gujarati"
    },
    {
      "language_code": "cnh",
      "language_name": "Hakha Chin"
    },
    {
      "language_code": "ha",
      "language_name": "Haoussa"
    },
    {
      "language_code": "haw",
      "language_name": "Hawaïen"
    },
    {
      "language_code": "iw",
      "language_name": "Hébreu"
    },
    {
      "language_code": "hil",
      "language_name": "Hiligaynon"
    },
    {
      "language_code": "hi",
      "language_name": "Hindi"
    },
    {
      "language_code": "hmn",
      "language_name": "Hmong"
    },
    {
      "language_code": "hu",
      "language_name": "Hongrois"
    },
    {
      "language_code": "hrx",
      "language_name": "Hunsrik"
    },
    {
      "language_code": "iba",
      "language_name": "Iban"
    },
    {
      "language_code": "ig",
      "language_name": "Igbo"
    },
    {
      "language_code": "ilo",
      "language_name": "Ilocano"
    },
    {
      "language_code": "id",
      "language_name": "Indonésien"
    },
    {
      "language_code": "ga",
      "language_name": "Irlandais"
    },
    {
      "language_code": "is",
      "language_name": "Islandais"
    },
    {
      "language_code": "it",
      "language_name": "Italien"
    },
    {
      "language_code": "ja",
      "language_name": "Japonais"
    },
    {
      "language_code": "jw",
      "language_name": "Javanais"
    },
    {
      "language_code": "kac",
      "language_name": "Jingpo"
    },
    {
      "language_code": "kl",
      "language_name": "Kalaallisut"
    },
    {
      "language_code": "kn",
      "language_name": "Kannada"
    },
    {
      "language_code": "kr",
      "language_name": "Kanouri"
    },
    {
      "language_code": "kk",
      "language_name": "Kazakh"
    },
    {
      "language_code": "kha",
      "language_name": "Khasi"
    },
    {
      "language_code": "km",
      "language_name": "Khmer"
    },
    {
      "language_code": "cgg",
      "language_name": "Kiga"
    },
    {
      "language_code": "kg",
      "language_name": "Kongo"
    },
    {
      "language_code": "rw",
      "language_name": "Kinyarwanda"
    },
    {
      "language_code": "ky",
      "language_name": "Kirghize"
    },
    {
      "language_code": "ktu",
      "language_name": "Kituba"
    },
    {
      "language_code": "trp",
      "language_name": "Kok Borok"
    },
    {
      "language_code": "kv",
      "language_name": "Komi"
    },
    {
      "language_code": "gom",
      "language_name": "Konkani"
    },
    {
      "language_code": "kri",
      "language_name": "Krio"
    },
    {
      "language_code": "ku",
      "language_name": "Kurde (Kurmanji)"
    },
    {
      "language_code": "ckb",
      "language_name": "Kurde (Sorani)"
    },
    {
      "language_code": "lo",
      "language_name": "Lao"
    },
    {
      "language_code": "ltg",
      "language_name": "Latgalien"
    },
    {
      "language_code": "la",
      "language_name": "Latin"
    },
    {
      "language_code": "lv",
      "language_name": "Letton"
    },
    {
      "language_code": "lij",
      "language_name": "Ligure"
    },
    {
      "language_code": "li",
      "language_name": "Limbourgeois"
    },
    {
      "language_code": "ln",
      "language_name": "Lingala"
    },
    {
      "language_code": "lt",
      "language_name": "Lituanien"
    },
    {
      "language_code": "lmo",
      "language_name": "Lombard"
    },
    {
      "language_code": "lg",
      "language_name": "Luganda"
    },
    {
      "language_code": "luo",
      "language_name": "Luo"
    },
    {
      "language_code": "lb",
      "language_name": "Luxembourgeois"
    },
    {
      "language_code": "mak",
      "language_name": "Makassar"
    },
    {
      "language_code": "mk",
      "language_name": "Macédonien"
    },
    {
      "language_code": "mad",
      "language_name": "Madurais"
    },
    {
      "language_code": "mai",
      "language_name": "Maïthili"
    },
    {
      "language_code": "ms-Arab",
      "language_name": "Malais (Jawi)"
    },
    {
      "language_code": "ms",
      "language_name": "Malais"
    },
    {
      "language_code": "ml",
      "language_name": "Malayalam"
    },
    {
      "language_code": "mg",
      "language_name": "Malgache"
    },
    {
      "language_code": "mt",
      "language_name": "Maltais"
    },
    {
      "language_code": "mam",
      "language_name": "Mam"
    },
    {
      "language_code": "gv",
      "language_name": "Mannois"
    },
    {
      "language_code": "mi",
      "language_name": "Maori"
    },
    {
      "language_code": "mr",
      "language_name": "Marathi"
    },
    {
      "language_code": "chm",
      "language_name": "Mari des prés"
    },
    {
      "language_code": "mh",
      "language_name": "Marshallais"
    },
    {
      "language_code": "mwr",
      "language_name": "Marwari"
    },
    {
      "language_code": "yua",
      "language_name": "Maya yucatèque"
    },
    {
      "language_code": "mni-Mtei",
      "language_name": "Meitei (Manipuri)"
    },
    {
      "language_code": "min",
      "language_name": "Minangkabau"
    },
    {
      "language_code": "lus",
      "language_name": "Mizo"
    },
    {
      "language_code": "mn",
      "language_name": "Mongol"
    },
    {
      "language_code": "bm-Nkoo",
      "language_name": "N’Ko"
    },
    {
      "language_code": "nhe",
      "language_name": "Nahuatl (Huastèque oriental)"
    },
    {
      "language_code": "ndc-ZW",
      "language_name": "Ndau"
    },
    {
      "language_code": "nr",
      "language_name": "Ndébélé du Sud"
    },
    {
      "language_code": "nl",
      "language_name": "Néerlandais"
    },
    {
      "language_code": "ne",
      "language_name": "Népalais"
    },
    {
      "language_code": "new",
      "language_name": "Néwar"
    },
    {
      "language_code": "no",
      "language_name": "Norvégien"
    },
    {
      "language_code": "nus",
      "language_name": "Nuer"
    },
    {
      "language_code": "oc",
      "language_name": "Occitan"
    },
    {
      "language_code": "or",
      "language_name": "Odia (Oriya)"
    },
    {
      "language_code": "om",
      "language_name": "Oromo"
    },
    {
      "language_code": "os",
      "language_name": "Ossète"
    },
    {
      "language_code": "udm",
      "language_name": "Oudmourte"
    },
    {
      "language_code": "ug",
      "language_name": "Ouïghour"
    },
    {
      "language_code": "uz",
      "language_name": "Ouzbek"
    },
    {
      "language_code": "ps",
      "language_name": "Pachto"
    },
    {
      "language_code": "pam",
      "language_name": "Pampangan"
    },
    {
      "language_code": "pag",
      "language_name": "Pangasinan"
    },
    {
      "language_code": "pa",
      "language_name": "Pendjabi (Gurmukhi)"
    },
    {
      "language_code": "pa-Arab",
      "language_name": "Pendjabi (Shahmukhi)"
    },
    {
      "language_code": "pap",
      "language_name": "Papiamento"
    },
    {
      "language_code": "jam",
      "language_name": "Créole jamaïcain"
    },
    {
      "language_code": "fa",
      "language_name": "Persan"
    },
    {
      "language_code": "ff",
      "language_name": "Peul"
    },
    {
      "language_code": "tl",
      "language_name": "Philippin"
    },
    {
      "language_code": "pl",
      "language_name": "Polonais"
    },
    {
      "language_code": "pt",
      "language_name": "Portugais (Brésil)"
    },
    {
      "language_code": "pt-PT",
      "language_name": "Portugais (Portugal)"
    },
    {
      "language_code": "kek",
      "language_name": "Q’eqchi’"
    },
    {
      "language_code": "qu",
      "language_name": "Quechua"
    },
    {
      "language_code": "rom",
      "language_name": "Romani"
    },
    {
      "language_code": "ro",
      "language_name": "Roumain"
    },
    {
      "language_code": "rn",
      "language_name": "Rundi"
    },
    {
      "language_code": "ru",
      "language_name": "Russe"
    },
    {
      "language_code": "se",
      "language_name": "Sami du Nord"
    },
    {
      "language_code": "sm",
      "language_name": "Samoan"
    },
    {
      "language_code": "sg",
      "language_name": "Sango"
    },
    {
      "language_code": "sa",
      "language_name": "Sanskrit"
    },
    {
      "language_code": "sat-Latn",
      "language_name": "Santali"
    },
    {
      "language_code": "nso",
      "language_name": "Sepedi"
    },
    {
      "language_code": "sr",
      "language_name": "Serbe"
    },
    {
      "language_code": "st",
      "language_name": "Sesotho"
    },
    {
      "language_code": "shn",
      "language_name": "Shan"
    },
    {
      "language_code": "sn",
      "language_name": "Shona"
    },
    {
      "language_code": "scn",
      "language_name": "Sicilien"
    },
    {
      "language_code": "szl",
      "language_name": "Silésien"
    },
    {
      "language_code": "sd",
      "language_name": "Sindhi"
    },
    {
      "language_code": "sk",
      "language_name": "Slovaque"
    },
    {
      "language_code": "sl",
      "language_name": "Slovène"
    },
    {
      "language_code": "so",
      "language_name": "Somali"
    },
    {
      "language_code": "su",
      "language_name": "Soundanais"
    },
    {
      "language_code": "sus",
      "language_name": "Soussou"
    },
    {
      "language_code": "sv",
      "language_name": "Suédois"
    },
    {
      "language_code": "sw",
      "language_name": "Swahili"
    },
    {
      "language_code": "ss",
      "language_name": "Swati"
    },
    {
      "language_code": "tg",
      "language_name": "Tadjik"
    },
    {
      "language_code": "ty",
      "language_name": "Tahitien"
    },
    {
      "language_code": "ber-Latn",
      "language_name": "Tamazight"
    },
    {
      "language_code": "ber",
      "language_name": "Tamazight (Tifinagh)"
    },
    {
      "language_code": "ta",
      "language_name": "Tamoul"
    },
    {
      "language_code": "tt",
      "language_name": "Tatar"
    },
    {
      "language_code": "crh",
      "language_name": "Tatar de Crimée"
    },
    {
      "language_code": "cs",
      "language_name": "Tchèque"
    },
    {
      "language_code": "ce",
      "language_name": "Tchétchène"
    },
    {
      "language_code": "cv",
      "language_name": "Tchouvache"
    },
    {
      "language_code": "te",
      "language_name": "Télougou"
    },
    {
      "language_code": "tet",
      "language_name": "Tétoum"
    },
    {
      "language_code": "th",
      "language_name": "Thaï"
    },
    {
      "language_code": "bo",
      "language_name": "Tibétain"
    },
    {
      "language_code": "ti",
      "language_name": "Tigrigna"
    },
    {
      "language_code": "tiv",
      "language_name": "Tiv"
    },
    {
      "language_code": "tpi",
      "language_name": "Tok Pisin"
    },
    {
      "language_code": "to",
      "language_name": "Tongien"
    },
    {
      "language_code": "tcy",
      "language_name": "Toulou"
    },
    {
      "language_code": "tyv",
      "language_name": "Touva"
    },
    {
      "language_code": "ts",
      "language_name": "Tsonga"
    },
    {
      "language_code": "tn",
      "language_name": "Tswana"
    },
    {
      "language_code": "tum",
      "language_name": "Tumbuka"
    },
    {
      "language_code": "tr",
      "language_name": "Turc"
    },
    {
      "language_code": "tk",
      "language_name": "Turkmène"
    },
    {
      "language_code": "ak",
      "language_name": "Twi"
    },
    {
      "language_code": "uk",
      "language_name": "Ukrainien"
    },
    {
      "language_code": "ur",
      "language_name": "Ourdou"
    },
    {
      "language_code": "ve",
      "language_name": "Venda"
    },
    {
      "language_code": "vec",
      "language_name": "Vénitien"
    },
    {
      "language_code": "vi",
      "language_name": "Vietnamien"
    },
    {
      "language_code": "war",
      "language_name": "Waray-Waray"
    },
    {
      "language_code": "wa",
      "language_name": "Wallon"
    },
    {
      "language_code": "xh",
      "language_name": "Xhosa"
    },
    {
      "language_code": "sah",
      "language_name": "Iakoute"
    },
    {
      "language_code": "yi",
      "language_name": "Yiddish"
    },
    {
      "language_code": "yo",
      "language_name": "Yoruba"
    },
    {
      "language_code": "zap",
      "language_name": "Zapotèque"
    },
    {
      "language_code": "zu",
      "language_name": "Zoulou"
    }
  ]

export const sourceTypes = [
    {
        name: "Toutes les sources",
        code_name: "all"
    },
    {
        name: "journaux",
        code_name: "journals"
    },
    {
        name: "universités",
        code_name: "universities"
    },
    {
        name: "ambassades",
        code_name: "ambassies"
    },
    {
        name: "partis politiques",
        code_name: "political_parties"
    },
    {
        name: "ministères",
        code_name: "ministers"
    },
]

export const listMiddle = [
    "De quoi parle-t-on ?",
    "De quels pays parle-t-on ?",
    "Quels pays en parlent ?"
]
