import React, { useState, useEffect } from "react"; 
import "./Review.css" 
import userApi from "../../HTTP/user"
import * as helpers from "../../Helper/utils"
import ReviewSelect from '../Review/ReviewSelect'; 
import ArticleBlocks from '../Review/ArticleBlocks'; 
import {addArticlesToReview} from '../../HTTP/articles'; 

const user = JSON.parse(localStorage.getItem("user"))
const AddToReview = () => {
	const params = new URLSearchParams(window.location.search)
	const articles = JSON.parse(decodeURIComponent(params.get('data'))) 
	articles.forEach(item => {
		delete item.publication_date;
		delete item.id;
	  });
	console.log(articles)
	// Vérifie si data existe pour éviter les erreurs
	const [reviews, setReviews] = useState([]); 
	useEffect(() => {
		const fetchReviews = async () => {
			const reviewsData = await userApi.getUserReviews(user.id); // Assurez-vous que ceci renvoie un tableau 
			setReviews(reviewsData.reviews);
		};  
		fetchReviews();
	  }, []);
	const addToReviewValidate = ()=>  {
		const reviewId =  document.getElementById('review-select').value
		addArticlesToReview(articles, reviewId).then((res) => {
			if(res === true) {
				alert('Articles ajoutés !')
				window.location.href = `/editreviews/${reviewId}`; 
			}
			else {
				alert('Erreur')
			}
		})
	}
	return (
		<div className="m-4">
			<h1>Ajouter ces articles à une revue de presse existante</h1>
			<ArticleBlocks articles={articles} />
			 <ReviewSelect reviews={reviews} />
			 <button className="bg-yellow-400 text-black font-bold py-2 px-4 rounded my-2" onClick={addToReviewValidate}>Ajouter ces articles</button>
		</div>
	)
}

export default AddToReview
