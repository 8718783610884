import { useEffect, useState } from "react";
import { countries as allCountries } from "../../global/countriesAndSources"
import {ProfileHeader } from "./profileHeader"
import externalScripts  from "../../Helper/useExternalScripts";
import GoogleCSE from "./GoogleCSE";
import translation from "../../HTTP/translation";
import Map from "../Map/Map";

export default function WhichCountriesSpeakAbout () {
    const queryCountry = new URLSearchParams(window.location.search).get('country')
    let country = queryCountry ? allCountries.find((c) => c.code === queryCountry) : null
    country = country
            || JSON.parse(sessionStorage.getItem("search_country"))
            || allCountries.find((c) => c.code === JSON.parse(localStorage.getItem('user'))?.country)
            || { name: "France", code: "fr", cse: "004899679196972058950:gvpguoasgrm" }
    
    const [selectedCountry] = useState(country)

    const selectNewCountry = async (country) => {
        const langStart = await translation.getLangFromCountry(selectedCountry.code)
        const langDest = await translation.getLangFromCountry(country.code)
        const trad = await translation.getTranslation(langStart, langDest, selectedCountry.name)
        sessionStorage.setItem('search_query', trad)
        window.location.href = `/${country.code}/which-countries-speak-about`
        sessionStorage.setItem("search_country", JSON.stringify(country))
    }

    return (
        <>
        <ProfileHeader 
                selectedCountry={selectedCountry} 
                setSelectedCountry = {(country) => selectNewCountry(country)}
            />
        <h1 className="ml-3">Quels pays en parlent ?</h1>
        <div id="bloc-map-themes" className="flex flex-col md:flex-row">
        <div className="w-full md:w-1/2 order-1 md:order-none">
        <GoogleCSE />
        </div>
        <div className="w-full md:w-1/2 order-1 md:order-none">
            <Map 
            selectedCountry={selectedCountry} 
            setSelectedCountry = {(country) => selectNewCountry(country)}
            />
        </div>
    </div>
        </>
    )
}