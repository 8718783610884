import React, { useState } from "react";
import "./Feed.css";
import "../Home/Home.css";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import pin from "../../media/pin.png";
import smile from "../../media/smile.png";
import favoris from "../../media/favoris.png";
//import DropDownMenuPrivacy from '../DropDownMenus/DropDownMenuPrivacy'; // Assurez-vous que le chemin est correct

const Message = () => {
  const [searchTerm, setSearchTerm] = useState(""); 

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    // Do something with the search term (e.g., make an API call)
    console.log("Search term:", searchTerm);
    alert(searchTerm);
  };

  
  return (
    <div className="msg-container">
      <div className="msg-head">
        <h1> <span style={{color:'black'}} >+ </span>  Que voulez-vous partager ? </h1>

            <div className="serachams">
              <Paper
                component="form"
                onSubmit={handleSubmit}
                sx={{
                  p: "2px 4px",
                  display: "flex",
                  alignItems: "center",
                  width: "386px",
                  marginTop: 0,
                  borderRadius: "67px",
                  border: "1px solid #00D5F3",
                }}
              >
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Recherche dans les articles..."
                  inputProps={{ "aria-label": "Rechercher" }}
                  value={searchTerm}
                  onChange={handleInputChange}
                />
                <IconButton type="submit" sx={{ p: "10px" }} aria-label="search">
                  <SearchIcon />
                </IconButton>
              </Paper>
            </div>
          </div>

          <div className="flex flex-col md:flex-row"> 
            <div className="md:w-1/2">
              <div className="inputOne m-4"> 
                 
              </div>
            </div>
            <div className="md:w-1/2">
              <div className="inputtow m-4">
                <div className="Message1">
                  <input
                  style={{backgroundColor:"#009ccc"}}
                    title="Write Message"
                    tabIndex="i"
                    pattern="\d+"
                    placeholder="Thème ..."
                    className="MsgInput"
                    type="text"
                  />
                  <span className="l"></span>
                </div>
                <div style={{ marginTop: "5px" }} className="Message1">
                  <textarea
                              style={{backgroundColor:"#009ccc"}}
                    tabIndex="i"
                    pattern="\d+"
                    placeholder="Rédiger une revue de presse ..."
                    className="Msgarea"
                    rows={16}
                  />
                  <span className="l"></span>
                </div>

                <div className="icons">
                  <img src={pin} alt="pin" />
                  <img src={smile} alt="smile" />
                  <img src={favoris} alt="favorite" />
                  <span className="material-symbols-outlined">delete</span>
                </div>
              </div>
            </div>
          </div> 
          
        </div>
  );
};

export default Message;
