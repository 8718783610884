import authHeader from "./auth.header.js"
import constants from './constants.js'

const HOST_URL = constants.API_SCHEME + "://" + constants.API_HOST_NAME + ":" + constants.API_PORT;

async function getTranslation(langStart, langDest, strStart){

    const response = await fetch(`${HOST_URL}/translate/${langStart}/${langDest}/${strStart}`, {
        method: 'GET',
        headers: {
            'Accept': 'text',
        },
    })

    if (!response.ok) {
        console.log("request failed\n. response code, ", response.code)
        console.log("response payload, ", await response.json())
        return ""
    }

    return await response.text()
}

async function getLangFromCountry(countryCode){

    const response = await fetch(`${HOST_URL}/translate/lang/${countryCode}`, {
        method: 'GET',
        headers: {
            'Accept': 'text',
        },
    })

    if (!response.ok) {
        console.log("request failed\n. response code, ", response.code)
        console.log("response payload, ", await response.json())
        return ""
    }

    return await response.text()
}

const translation = {
    getTranslation,
    getLangFromCountry
}
export default translation;
