import React, { useContext, useState } from 'react'
import "./Profile.css"
import verificato from "../../media/verificato.png"; 
import { Context } from '../../Helper/Context';
import Button from "@mui/material/Button";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { styled, alpha } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import userApi from "../../HTTP/user" 
import SwitchList from '../Switch/Switch'; // Assurez-vous que le chemin est correct

const user = JSON.parse(localStorage.getItem("user"))

const StyledMenu = styled((props) => (
	<Menu
		elevation={0}
		anchorOrigin={{
			vertical: "bottom",
			horizontal: "right",
		}}
		transformOrigin={{
			vertical: "top",
			horizontal: "right",
		}}
		{...props}
	/>
))(({ theme }) => ({
	"& .MuiPaper-root": {
		borderRadius: 8,
		marginTop: theme.spacing(1),
		minWidth: 210,
		color: "#00D5F3", // Change text color to #00D5F3
		backgroundColor: "transparent", // Change background color to transparent
		boxShadow:
			"rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
		"& .MuiMenu-list": {
			padding: "4px 0",
		},
		"& .MuiMenuItem-root": {
			"& .MuiSvgIcon-root": {
				fontSize: 25,
				color: "#00D5F3", // Change text color to #00D5F3
				backgroundColor: "transparent", // Change background color to transparent
				// marginRight: theme.spacing(1.5),
			},
			"&:active": {
				backgroundColor: alpha(
					theme.palette.primary.main,
					theme.palette.action.selectedOpacity
				),
			},
		},
	},
}));

const Profile = () => { 
    const [formData, setFormData] = useState({
        lastname: user.lastname,
        firstname: user.firstname,
        email: user.email,
        oldpassword: '',
        newpassword: '',
        bio: user.bio || '',
        interests: '',
        skills: '',
        address: user.address || '',
        phone: user.phone || '',
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Traiter les données du formulaire, comme envoyer à une API
		try {
            const response = userApi.updateUser(user.id,formData);
            console.log(response)
            // il faut aussi récupérer l'id et le privacy du user si on met à jour en local! 
            formData.id = user.id
            formData.private = user.private
            formData.token = user.token
			localStorage.setItem("user", JSON.stringify(formData)); 
            alert('Profil mis à jour !')
        } catch (error) {
            console.error('Erreur lors de la mise à jour', error);
        }
    };
  
	const { SetFil } = useContext(Context)

	const [anchorEl, setAnchorEl] = useState(null);

	const open = Boolean(anchorEl);
	const [setAnchorEl2] = useState(null);

	const handleClose2 = () => {
		setAnchorEl2(null);
		alert("Edit A propos")
	};

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

    const handleFileUpload = async (event) => {
        const file = event.target.files[0]; // Obtenez le fichier sélectionné
        if (!file) {
            console.log("Aucun fichier sélectionné.");
            return;
        }
		try {
		  const result = await userApi.setProfilePic(user.id, file); 
		  user.profile_picture = result;  
		  localStorage.setItem('user', JSON.stringify(user));
		  document.getElementById('profile-pic').src = result;
		} catch (error) {
		  console.error('Erreur lors de l\'envoi du fichier:', error);
		}
	  };

	const [profileHover, setProfileHover] = useState(false)

	return (
		<div className='profileContainer'>
			<SwitchList />
			<div className="profimg">
				<img onMouseEnter={() => setProfileHover(true)} onMouseLeave={() => setProfileHover(false)} src={user.profile_picture || process.env.PUBLIC_URL+"/profile_default.png" } alt="profile"/>
				{profileHover &&
					<div onMouseEnter={() => setProfileHover(true)}
						onMouseLeave={() => setProfileHover(false)}
						style={{
							margin: 0,
							alignItems: "center",
							display: "flex",
							marginLeft: "-59px",
							position: "relative",
							left: "-15px",
							width: "59px"
						}}
						className="file-input"
					>
						<input
							type="file"
							name="file-input"
							id="file-input"
							className="file-input__input"
							onChange={handleFileUpload}	
						/>
						<label className="file-input__label" htmlFor="file-input">
							<svg
								aria-hidden="true"
								focusable="false"
								data-prefix="fas"
								data-icon="upload"
								className="svg-inline--fa fa-upload fa-w-16"
								role="img"
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 512 512"
							>
								<path fill="currentColor" d="M296 384h-80c-13.3 0-24-10.7-24-24V192h-87.7c-17.8 0-26.7-21.5-14.1-34.1L242.3 5.7c7.5-7.5 19.8-7.5 27.3 0l152.2 152.2c12.6 12.6 3.7 34.1-14.1 34.1H320v168c0 13.3-10.7 24-24 24zm216-8v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h136v8c0 30.9 25.1 56 56 56h80c30.9 0 56-25.1 56-56v-8h136c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"></path>
							</svg>
						</label>
					</div>
				}


				<div className="mb-2" >
					<div className="profnameprof">
						<div onClick={() => { SetFil("ProfilInfo") }} style={{ display: "flex" }}>
							<h2> 
								{ `${user?.firstname} ${user?.lastname}` }
							</h2>
							<img className="verificato" src={verificato} alt="verification"/>
						</div>

						{/* <span style={{ marginLeft: "3px" }} className="infofriendspan">A propos</span> */}


						<div className="list1">
							<Button
								id="demo-customized-button"
								aria-controls={open ? "demo-customized-menu" : undefined}
								aria-haspopup="true"
								aria-expanded={open ? "true" : undefined}
								variant="contained"
								disableElevation
								onClick={handleClick}
								endIcon={<KeyboardArrowDownIcon />}
								style={{
									backgroundColor: "transparent", color: "#02b3e3", fontSize: "15px", fontWeight: 700, position: "relative"
								}}
							>
								A propos

							</Button>

							<StyledMenu
								id="demo-customized-menu"
								MenuListProps={{
									"aria-labelledby": "demo-customized-button",
								}}
								anchorEl={anchorEl}
								open={open}
								onClose={handleClose}
							>

								<ul className="ulflag">
									<li><button style={{fontSize: 22, paddingTop: 6}} onClick={() => { localStorage.removeItem("user"); localStorage.removeItem("token"); window.location.reload() }}> Déconnexion </button></li>
									<li><a href="/profile">Retour au Profil</a></li>
								</ul>

								{/* <Divider sx={{ my: 0.5 }} /> */}
							</StyledMenu>
						</div>


					</div>
					<button onClick={() => window.location.href = "/Subscription"} className="btnpremm">Passer a la version Premium</button>
				</div>
				 
			</div>
			
			 
        <form onSubmit={handleSubmit} className="max-w-xl mb-5 my-2">
            <div className="mt-5 mb-5"> 
                    <h2 className="text-base font-semibold leading-7 text-gray-900 mt-2 mb-2">Modifier vos informations</h2>

                    <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        {/* Last Name */}
                        <div className="sm:col-span-3">
                            <label htmlFor="lastname" className="block text-sm font-medium leading-6 text-gray-900">
                                Nom
                            </label>
                            <div className="mt-2">
                                <input
                                    type="text"
                                    name="lastname"
                                    id="lastname"
                                    value={formData.lastname}
                                    onChange={handleChange}
									autoComplete="Nom"
                                    className="block w-full rounded-md border-0 px-3 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cyan-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        {/* First Name */}
                        <div className="sm:col-span-3">
                            <label htmlFor="firstname" className="block text-sm font-medium leading-6 text-gray-900">
                                Prénom
                            </label>
                            <div className="mt-2">
                                <input
                                    type="text"
                                    name="firstname"
                                    id="firstname"
                                    value={formData.firstname}
                                    onChange={handleChange}
									autoComplete="Prénom"
                                    className="block w-full rounded-md border-0 px-3 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cyan-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        {/* Email */}
                        <div className="sm:col-span-4">
                            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                Email
                            </label>
                            <div className="mt-2">
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    value={formData.email}
                                    onChange={handleChange}
									autoComplete="email"
                                    className="block w-full rounded-md border-0 px-3 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cyan-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        {/* Old Password */}
						 
                        <div className="sm:col-span-3">
                            <label htmlFor="oldpassword" className="block text-sm font-medium leading-6 text-gray-900">
                                Ancien mot de passe 
                            </label>
                            <div className="mt-2">
                                <input
                                    type="password"
                                    name="oldpassword"
                                    id="oldpassword"
                                    value={formData.oldpassword}
                                    onChange={handleChange}
									autoComplete="old-password"
                                    className="block w-full rounded-md border-0 px-3 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cyan-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        {/* New Password */}
                        <div className="sm:col-span-3">
                            <label htmlFor="newpassword" className="block text-sm font-medium leading-6 text-gray-900">
                                Nouveau mot de passe
                            </label>
                            <div className="mt-2">
                                <input
                                    type="password"
                                    name="newpassword"
                                    id="newpassword"
                                    value={formData.newpassword}
                                    onChange={handleChange}
									autoComplete="new-password"
                                    className="block w-full rounded-md border-0 px-3 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cyan-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        {/* Bio */}
                        <div className="col-span-full">
                            <label htmlFor="bio" className="block text-sm font-medium leading-6 text-gray-900">
                                Bio
                            </label>
                            <div className="mt-2">
                                <textarea
                                    id="bio"
                                    name="bio"
                                    rows={3}
                                    maxLength="250"
                                    value={formData.bio}
                                    onChange={handleChange}
                                    className="block w-full rounded-md border-0 px-3 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cyan-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        {/* Interests */}
                        {/* ... Similar to 'Bio' field ... */}

                        {/* Skills */}
                        {/* ... Similar to 'Bio' field ... */}

                        {/* Address */}
                        <div className="col-span-full">
                            <label htmlFor="address" className="block text-sm font-medium leading-6 text-gray-900">
                                Adresse
                            </label>
                            <div className="mt-2">
                                <input
                                    type="text"
                                    name="address"
                                    id="address"
                                    value={formData.address}
                                    onChange={handleChange}
                                    className="block w-full rounded-md border-0 px-3 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cyan-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        {/* Phone */}
                        <div className="sm:col-span-3">
                            <label htmlFor="phone" className="block text-sm font-medium leading-6 text-gray-900">
                                Téléphone
                            </label>
                            <div className="mt-2">
                                <input
                                    type="text"
                                    name="phone"
                                    id="phone"
                                    value={formData.phone}
                                    onChange={handleChange}
                                    className="block w-full rounded-md border-0 px-3 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cyan-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        {/* ... Autres champs si nécessaire ... */}
                    </div>
                </div>

                {/* Boutons Submit et Cancel */}
                <div className="mt-6 flex items-center justify-end gap-x-6"> 
                    <button
                        type="submit"
                        className="rounded-md bg-yellow-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-yellow-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600"
                    >
                        Enregistrer
                    </button> 
            </div>
        </form> 
			 
		</div>
	)
}

export default Profile
