import React, { useEffect, useState } from 'react';
import { languages } from '../../global/countriesAndSources';

function Translate({ initialLang = 'fr' }) {
  const [currentLang, setCurrentLang] = useState(initialLang);

  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "fr",
        autoDisplay: false
      },
      "google_translate_element"
    );
  };

  const updateUrlLang = (lang) => {
    const url = new URL(window.location.href);
    url.searchParams.set('lang', lang);
    window.history.pushState({}, '', url);
  };

  useEffect(() => {
    // Vérifier si le script a déjà été ajouté
    const existingScript = document.getElementById('google_translate_script');
    if (!existingScript) {
      var addScript = document.createElement("script");
      addScript.setAttribute("src", "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit");
      addScript.setAttribute("id", "google_translate_script"); // Ajouter un identifiant au script
      document.body.appendChild(addScript);
    }

    // Initialiser la fonction de rappel pour le widget
    window.googleTranslateElementInit = googleTranslateElementInit;

    // Observer les changements de langue
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'attributes' && mutation.attributeName === 'lang') {
          const newLang = document.documentElement.lang;
          setCurrentLang(newLang);
          updateUrlLang(newLang)
        }
      });
    });

    observer.observe(document.documentElement, { attributes: true });

    // Optionnel: Nettoyer en retirant le script et la fonction de rappel si le composant est démonté
    return () => {
      if (existingScript) {
        document.body.removeChild(existingScript);
      }
      window.googleTranslateElementInit = undefined;
      observer.disconnect();
    };
  }, []);

  const handleLanguageChange = (event) => {
    const newLang = event.target.value
    console.log(newLang)
    setCurrentLang(newLang);
    updateUrlLang(newLang);
    const googleTranslateSelect = document.querySelector('.goog-te-combo');
    if (googleTranslateSelect) {
      googleTranslateSelect.value = newLang;
      googleTranslateSelect.dispatchEvent(new Event('change'));
      if (newLang === 'fr') {
        closeGoogleTranslateToolbar();
      }
    } else {
      console.log('NOPE')
    }
  }

  const closeGoogleTranslateToolbar = () => {

    // Reset Google Translate selection to the default language (Français)
    const googleTranslateSelect = document.querySelector('.goog-te-combo');
    if (googleTranslateSelect) {
      googleTranslateSelect.value = 'fr';
      googleTranslateSelect.dispatchEvent(new Event('change'));
    }
  };


  return (
    <>
      <div id="google_translate_element" style={{ display:'none' }}></div>
      <select style={{ backgroundColor: "#009AAD", color:"white", paddingTop: 5, paddingBottom: 5, paddingLeft: 5, paddingRight: 5}} className='search_menu_selection absolute top-0 right-0 m-4' value={currentLang} onChange={handleLanguageChange}>
      {
        languages.map((item, index) =>
          <option style={{color:"black"}}className='actuins' key={index} value={item.language_code}>{item.language_name}</option>
        )
      }
      </select>
    </>
  );
}

export default Translate;