import React from 'react';
import * as helpers from "../../Helper/utils"
import "./ReviewBlocks.css"
const ReviewSelect = ({ reviews }) => { 
  if (!Array.isArray(reviews)) {
    console.error('reviews doit être un tableau', reviews);
    return null; // Ou retourner un fallback UI
  } 
  return (
    <div>
      <h2>Choisissez la revue de presse</h2>
      <select name="review_id" className="p-2" id="review-select">
        {reviews.map((review, reviewIndex) => (
          <option key={reviewIndex} value={review.id}>{review.theme}</option> 
        ))}
      </select>     
    </div>
  );
};

export default ReviewSelect;
