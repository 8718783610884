// import { useEffect } from 'react';

export default function externalScripts({url, type, id, currentLocation}){
        console.log("inside externalScripts")

        if (document.getElementById(id)){
            document.getElementById(id).setAttribute("src", url)
            console.log("got element by id, set attribute")
        }

        const head = document.querySelector("head")
        const script = document.createElement("script")
        script.setAttribute("id", id)
        script.setAttribute("src", url)

        if (type === "async") script.setAttribute("async", "")

        head.appendChild(script)

        console.log("finished externalScripts")

        //AsyncSearchBarModif()
}

export function removeScript(id) {
    // console.log("inside removeScript")
    window.location.hash = ""
    const cse_main = document.getElementById(id)
    const cse_ad = document.querySelectorAll('script[src="//cse.google.com/adsense/search/async-ads.js"]')
    const cse_elm = document.querySelectorAll('script[src="//https://www.google.com/cse/static/element/e992cd4de3c7044f/cse_element__fr.js?usqp=CAI%3D"]')
    const cse_all = [...cse_ad, ...cse_elm]

    if (cse_main) {
        cse_main.remove()
        console.log("cse_main script removed")
    }

    cse_all.forEach(s => {
        if (s){
            console.log("dumb script removed: src=", s.src)
            s.remove()
        }
    })

    return true
}
