import { useEffect, useState } from "react";
import { countries as allCountries } from "../../global/countriesAndSources"
import {ProfileHeader } from "./profileHeader"
import externalScripts  from "../../Helper/useExternalScripts";
import { useParams } from 'react-router-dom';
import translation from "../../HTTP/translation";
import "./GoogleCSE.css"
import "./articles.css"
import GoogleCSE from "./GoogleCSE";

export default function WhatThisCountryTalkAbout () {
    sessionStorage.setItem('listmiddle', "De quoi parle-t-on ?")

    let { countrycode } = useParams(); // Assurez-vous que le nom du paramètre correspond à celui défini dans votre route 

    const queryCountry = new URLSearchParams(window.location.search).get('country')
    let country = countrycode ? allCountries.find((c) => c.code === countrycode) : null
    country = country
            || JSON.parse(sessionStorage.getItem("search_country"))
            || allCountries.find((c) => c.code === JSON.parse(localStorage.getItem('user'))?.country)
            || { name: "France", code: "fr", cse: "004899679196972058950:gvpguoasgrm" }
    if (JSON.parse(sessionStorage.getItem('search_country')) === null) {
        sessionStorage.setItem('search_country',JSON.stringify(country))
    }
    
    const [selectedCountry] = useState(country)
    console.log(country)

    const selectNewCountry = async (country) => {
        console.log(country.name)
        const langDest = await translation.getLangFromCountry(country.code)
        let trad
        if (langDest !== document.documentElement.lang) trad = await translation.getTranslation(document.documentElement.lang, langDest, country.name)
        sessionStorage.setItem("search_query", (langDest !== document.documentElement.lang) ? trad : country.name)
        window.location.href = `/${country.code}/what-this-country-talk-about`
        sessionStorage.setItem("search_country", JSON.stringify(country))
    }
    
    return (
        <>
        <ProfileHeader 
                selectedCountry={selectedCountry} 
                setSelectedCountry = {(country) => selectNewCountry(country)}
            />
        <h1 className="ml-3">De quoi parle-t-on ?</h1>
        <GoogleCSE />
        </>
    )
}