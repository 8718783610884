import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import "./Review.css" 
import { getReviewById } from "../../HTTP/articles"
import * as helpers from "../../Helper/utils"
import facebook from "../../media/facebook.svg";
import twitter from "../../media/x.png";
import linkedin from "../../media/linkedin.svg"; 
import email from "../../media/email.png";  
import MetaData from '../../MetaData'; // Assurez-vous d'importer le composant MetaData

const Review = () => {
	let { reviewid } = useParams(); // Assurez-vous que le nom du paramètre correspond à celui défini dans votre route 
	const [review, setReview] = useState([]); 
	  useEffect(() => {
      const fetchReview = async () => {
        const reviewData = await getReviewById(reviewid); // Assurez-vous que ceci renvoie un tableau
        console.log(reviewData); // Debug: Qu'affiche cela ?
        setReview(reviewData);
      };
	  
		  fetchReview();
	  }, []);

	  const currentUrl = window.location.href; 

	return (
		<div>
			< MetaData
				title={`Zemus Revue de presse : ${review.theme}`}
				description={review.presentation}
				image="/preview.png"
			/>
			{(typeof review === 'object') ? (
				Array.isArray(review.articles) ?
				<> 
					<div className="review w-full">
						<div className="review-block bg-white rounded-lg shadow m-2">
						<button onClick={() => window.history.back()} className="backbutton bg-cyan-500 rounded-xl font-semibold py-1 px-4 absolute">
						← Retour
						</button>
							<div className="review-header p-4 block">
								<strong className="block text-uppercase mt-2">{review.theme}</strong>
								<p  className="block">{review.presentation}</p>
							</div>
							<div className="review-inner p-4">
								<div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-4"> 
									{review.articles && review.articles.map((article, articleIndex, { length }) => {
											let colSpan = "col-span-1";
											let heightSpan = "w-full h-48 object-cover rounded";
											if (length === 3 && articleIndex === 0) { // Premier article prend deux colonnes s'il y a trois articles
											colSpan = "md:col-span-1";
											heightSpan = "w-full h-48 object-cover rounded"
											} else if (length === 4) { // Chaque article prend exactement un quart de l'espace
											colSpan = "md:col-span-1";
											heightSpan = "w-full h-48 object-cover rounded"
											}

											return (
											<div key={articleIndex} className={`${colSpan}`}>
												<div className="article-content bg-white rounded-lg shadow relative">
												<a href={ helpers.htmlDecode(article.link)} target="blank">
													<img src={ helpers.htmlDecode(article.image || process.env.PUBLIC_URL + "/news_icon2.jpg") /**  IMAGE  */} alt={article.title} className={`${heightSpan}`} /> 
													<div className="article-title p-2">
													<h3 className="py-1">{article.title} </h3>
													<span className="article-origin block"><strong>{article.country}</strong> | {helpers.getDomainName(article.link)} </span>
													</div>
												</a>
												</div>
											</div>
											);
										})}
								</div> 
								<div className="review-footer mt-4">
									<div className="flex space-x-2">
										<a href={`https://www.facebook.com/sharer/sharer.php?u=${currentUrl}`} target="_blank" rel="noopener noreferrer"  >
											<img src={facebook} alt="Facebook"/> 
										</a>  
										<a href={`https://twitter.com/intent/tweet?url=${currentUrl}`} target="_blank" rel="noopener noreferrer" 
										style={{marginTop: '4px'}}
										>
											<img src={twitter} alt="X" width="40" /> 
										</a> 
										<a href={`https://www.linkedin.com/shareArticle?mini=true&url=${currentUrl}`} target="_blank" rel="noopener noreferrer" >
											<img src={linkedin} alt="LinkedIn"/>
										</a> 
										<a href={`mailto:?subject=Regarde%cette%20page&body=${currentUrl}`}
										style={{marginTop: '4px'}}
										>
											<img src={email} alt="Mail" width="40"/> 
										</a>
									</div>
								</div>
							</div> 
						</div>
					</div>
				</> : 
					<div className="bg-red-500 text-white p-4 rounded-lg shadow-md" role="alert"> 
						<span className="block sm:inline">Cette revue de presse est privée</span>
					</div>
			) :
				<div>
					<div className="bg-red-500 text-white p-4 rounded-lg shadow-md" role="alert"> 
						<span className="block sm:inline">Cette revue de presse est privée</span>
					</div>
				</div>
			}
		</div>
	)
}

export default Review
