
import { useEffect, useState } from "react";
import { countries as allCountries } from "../../global/countriesAndSources"
import {ProfileHeader } from "./profileHeader"
import externalScripts  from "../../Helper/useExternalScripts";
import GoogleCSE from "./GoogleCSE";
import thematics from "../../HTTP/thematics";
import translation from "../../HTTP/translation";
import Map from "../Map/Map";

export default function TheCountrySpeaksOf () {

    const [themes, setThemes] = useState([])

    let country;
    if(JSON.parse(sessionStorage.getItem('search_themes_country')) !== null) {
        country = JSON.parse(sessionStorage.getItem('search_themes_country'))
    } else {
        const queryCountry = new URLSearchParams(window.location.search).get('country')
        country = queryCountry ? allCountries.find((c) => c.code === queryCountry) : null
        country = country
                || JSON.parse(sessionStorage.getItem("search_country"))
                || allCountries.find((c) => c.code === JSON.parse(localStorage.getItem('user'))?.country)
                || { name: "France", code: "fr", cse: "004899679196972058950:gvpguoasgrm" };
        sessionStorage.setItem('search_themes_country',JSON.stringify(country))
    }
    
    const [selectedCountry, setSelectedCountry] = useState(country)
    const selectNewCountry = (country) => {
        setSelectedCountry(country)
        sessionStorage.setItem("search_themes_country", JSON.stringify(country))
    }

    useEffect (() => {
        const setThematics = async () => {
            const fromDb = await thematics.fetchThematicsFromCountry(JSON.parse(sessionStorage.getItem('search_themes_country')).code)
            setThemes(fromDb)
        }
        setThematics()
        console.log("mise en place terminée")
    }, [selectedCountry])

    const handleTheme = async (item) => {
        const lang = await translation.getLangFromCountry(JSON.parse(sessionStorage.getItem("search_country")).code)
        let query;
        if (lang !== 'fr') {
            query = await translation.getTranslation('fr', lang, item)
        } else {
            query = item
        }
        const input = document.querySelector('input#gsc-i-id1')
        sessionStorage.setItem("search_query",query)
        window.launchSearch = false
        console.log("la recherche est lancée sur le terme " + query)
        if (query) {
            for (let i = 0; i < 5; i++){setTimeout(() => input.value = query, 500)}
        }
        const button = document.querySelector("button.gsc-search-button-v2")
        if (button){
            for (let i = 0; i < 5; i++){setTimeout(() => button.click(), 500)}
        }
        window.launchSearchDone = true
        console.log(item)
    }
            
    return (
        <>
        <ProfileHeader 
                selectedCountry={selectedCountry} 
                setSelectedCountry = {(country) => selectNewCountry(country)}
            />
        <h1 className="ml-3">De quels pays parle-t-on ?</h1>
        <div id="bloc-map-themes" className="flex flex-col md:flex-row">
        <div className="w-full md:w-1/3 order-1 md:order-none">
            <Map 
            selectedCountry={selectedCountry} 
            setSelectedCountry = {(country) => selectNewCountry(country)}
            />
        </div>
        <div className="w-full md:w-1/3 order-1 md:order-none">
            <GoogleCSE />
        </div>
        <div id="themes" className="w-full md:w-1/3 order-2 md:order-none flex flex-col items-center md:items-end p-4">
        {
            themes.map((item, index) => 
                <div 
            key={index} 
            className="mb-2 px-4 py-2 bg-bluezemus text-white rounded-full shadow-md hover:bg-black transition duration-300 ease-in-out cursor-pointer text-sm md:text-base w-auto md:w-3/4 text-center"
            onClick={() => handleTheme(item.theme)}
                >
                    {item.theme}
                </div>
            )
        }
        </div>
    </div>
        
        </>
    )
}